import {i18n} from "../i18n";
import {FILTER_TYPE_CHECKBOX} from "../constant";

export const PREFERENCE_FILTER_GROUP_INCLUDE = 'include';
export const PREFERENCE_FILTER_GROUP_IS_INTERNAL = 'isInternal';
export const PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE = 'preferenceType';
export const PREFERENCE_FILTER_GROUP_WHEN = 'when';
export const PREFERENCE_FILTER_GROUP_GROUPS = 'group';

export const createListOfFilters = () => {
    return [
        {
            filterTitle: i18n.get('preference.scheme.include.true'),
            filterName: `${PREFERENCE_FILTER_GROUP_INCLUDE}.true`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_INCLUDE,
        },
        {
            filterTitle: i18n.get('preference.scheme.include.false'),
            filterName: `${PREFERENCE_FILTER_GROUP_INCLUDE}.false`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_INCLUDE,
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.product'),
            filterName: `${PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE}.product`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE,
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.recipe'),
            filterName: `${PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE}.recipe`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE,
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.recipe-characteristic'),
            filterName: `${PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE}.recipe-characteristic`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE,
        },
        {
            filterTitle: i18n.get('preference.scheme.preferenceType.property'),
            filterName: `${PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE}.property`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_PREFERENCE_TYPE,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.morning'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.morning`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.after-morning'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.after-morning`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.afternoon'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.afternoon`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.before-evening'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.before-evening`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.evening'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.evening`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.night'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.night`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.when.no-matter'),
            filterName: `${PREFERENCE_FILTER_GROUP_WHEN}.no-matter`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_WHEN,
        },
        {
            filterTitle: i18n.get('preference.scheme.isInternal.true'),
            filterName: `${PREFERENCE_FILTER_GROUP_IS_INTERNAL}.true`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_IS_INTERNAL,
        },
        {
            filterTitle: i18n.get('preference.scheme.isInternal.false'),
            filterName: `${PREFERENCE_FILTER_GROUP_IS_INTERNAL}.false`,
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: PREFERENCE_FILTER_GROUP_IS_INTERNAL,
        },
    ];
};
