import React, { useState, useEffect } from 'react';
import { popupOpenService } from '../../../services/popupOpenService';
import Modal from '../../Modal';
import { MODAL_SMALL_SIZE } from '../../helper';
import UpdateSeveralPropertiesSaveButton from './UpdateSeveralPropertiesSaveButton';
import UpdateSeveralPropertiesFormsList from './UpdateSeveralPropertiesFormsList';
import { i18n } from '../../../i18n';
import UpdateSeveralPropertiesListOfProperties from './UpdateSeveralPropertiesListOfProperties';
import { updateSeveralPropertiesService } from '../../../services/updateSeveralPropertiesService';

const UpdateSeveralPropertiesModalContent = ({propertyId, handleClose}) => (
    <>
        <h2 className="modal__title block__title-h3">{i18n.get('app.page.product.modal.updateProperties.title')}</h2>
        <div className="modal__list">
            <UpdateSeveralPropertiesFormsList propertyId={propertyId}/>
            <UpdateSeveralPropertiesListOfProperties />
        </div>
        <div className="modal__button-group property__button-group">
            <button className="block__button button button_outline property__button" onClick={() => handleClose(null)}>
                <span className="button__content">{i18n.get('app.page.product.modal.updateProperties.cancel')}</span>
            </button>
            <UpdateSeveralPropertiesSaveButton closeCallback={handleClose}/>
        </div>
    </>
);

const UpdateSeveralPropertiesPopup = () => {
    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [propertyId, setPropertyId] = useState('');

    const changePopupState = (newValue) => {
        setPropertyId(newValue);
        setPopupIsOpen(true);
    };

    useEffect(() => {
        popupOpenService.registerPopup('apply-to', changePopupState);
        return () => popupOpenService.unRegisterPopup(changePopupState);
    }, []);

    const closePopup = () => {
        updateSeveralPropertiesService.deleteFormFromFormsList();
        updateSeveralPropertiesService.deletePropertyFromPropertiesList();
        setPopupIsOpen(false);
    };

    return (
        popupIsOpen ?
            <Modal handleCloseModal={closePopup} size={MODAL_SMALL_SIZE} 
                content={<UpdateSeveralPropertiesModalContent handleClose={closePopup} propertyId={propertyId}/>}/>
            :
            null
    );
};

export default UpdateSeveralPropertiesPopup;
