import { POPUP_KEY_RESTORE_PRODUCT } from "../constant";
import { i18n } from "../i18n";
import { PRODUCT_URL, postFetchRequest } from "../store/requests";
import { productsService } from "./productsService";
import { productsStorage } from "./productsStorage";
import {errorCallback, makeUrl} from "../util";

export const productRestoreService = {
    restoreItem(itemId, notifyItemsList) {
        postFetchRequest(makeUrl([PRODUCT_URL, itemId, '/?restore=true']), null, notifyItemsList, errorCallback, true);
    },
    getItemTitle(itemId) {
        return productsService.getLocalization(i18n.APP_LOCALE, productsStorage.getById(itemId).productCode);
    },
    getPopupKey() {
        return POPUP_KEY_RESTORE_PRODUCT;
    },
};
