import { POPUP_KEY_RESTORE_PROPERTY } from "../constant";
import { i18n } from "../i18n";
import { PROPERTIES_URL, postFetchRequest } from "../store/requests";
import { propertiesService } from "./propertiesService";
import { propertiesStorage } from "./propertiesStorage";
import {errorCallback, makeUrl} from "../util";

export const propertyRestoreService = {
    restoreItem(itemId, notifyListItem) {
        postFetchRequest(makeUrl([PROPERTIES_URL, itemId, '/?restore=true']), null, notifyListItem, errorCallback, true);
    },
    getItemTitle(itemId) {
        return propertiesService.getLocalization(i18n.APP_LOCALE, propertiesStorage.getById(itemId).propertyName);
    },
    getPopupKey() {
        return POPUP_KEY_RESTORE_PROPERTY;
    },
};
