import { FILTER_TYPE_CHECKBOX, LOCALE_RU, NOTIFICATION_EVENT_TYPE_PHRASES_GENERATED, NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL, NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL, SMALL_WEB_SCREEN_WIDTH, TABLET_WIDTH } from "../constant";
import { i18n } from "../i18n";
import { G_UNIT_NAME, MCG_UNIT_NAME, MG_UNIT_NAME } from "./productsTab/products/ProductPropertyNumberValue";

export const MODAL_SMALL_SIZE = 'small';
export const MODAL_SUPER_SMALL_SIZE = 'xs';

export const isSmallWebScreen = (width) => {
    return width < SMALL_WEB_SCREEN_WIDTH && width > TABLET_WIDTH;
};

export const MAX_ITEMS_COUNT = 15;

export const ADD_PRODUCT_MACRO_ELEMENTS = 'macronutrient';
export const ADD_PRODUCT_MICRO_ELEMENTS = 'micronutrient';
export const ADD_PRODUCT_PHYSICAL_PROPERTIES = 'physical';
export const ADD_PRODUCT_NON_NUTRIENT = 'non-nutrient';

export const ADD_PRODUCT_PROPERTY_PROTEIN = 'protein';
export const ADD_PRODUCT_PROPERTY_MINERALS = 'minerals';

export const MAIN_RECIPE_ELEMENTS = 'base';
export const SECONDARY_RECIPE_ELEMENTS = 'adds';
export const TASTE_RECIPE_PROPERTIES = 'tasty';

export const PREFERENCE_LABEL_BASIC = 'base';
export const PREFERENCE_LABEL_FAVORITES = 'collection';
export const PREFERENCE_LABEL_SEASON = 'season';

export const addLocaleToList = (array) => {
    for(let i = 0; i < array.length; i++) {
        array[i].locale = LOCALE_RU;
    }
    return array;
};

export const sortByDate = (a, b) => {
    return new Date(b.dateCreated) - new Date(a.dateCreated);
};

export const checkItemLocaleCode = (itemCode) => itemCode !== null && itemCode !== undefined;

export const createPhrasesMessage = (type, preferenceAsString) => {
    let result = '';
    if(type === NOTIFICATION_EVENT_TYPE_PHRASES_GENERATED) {
        result = `${i18n.get('app.page.preference.phrasesForPreference')} «${preferenceAsString}» ${i18n.get('app.page.preference.generated')}`;
    }
    if(type === NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL) {
        result = `${i18n.get('app.page.preference.phrasesGenerateFail')} «${preferenceAsString}»`;
    }
    if(type === NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL) {
        result = `${i18n.get('app.page.preference.preferenceGenerateFail')} «${preferenceAsString}»`;
    }
    return result;
};

const PRODUCT_CATEGORIES_LIST = [
    {
        id: 'grain',
    },        
    {
        id: 'nut',
    },         
    {
        id: 'berry',
    }, 
    {
        id: 'vegetable',
    },        
    {
        id: 'fruit',
    },         
    {
        id: 'meat',
    }, 
    {
        id: 'fish',
    }, 
    {
        id: 'poultry',
    }, 
    {
        id: 'dairy',
    },        
    {
        id: 'legumes',
    },         
    {
        id: 'salad-green',
    }, 
    {
        id: 'egg',
    },        
    {
        id: 'cheese',
    },         
    {
        id: 'pasta',
    },
    {
        id: 'oil',
    }, 
    {
        id: 'spices',
    }, 
    {
        id: 'sauce',
    }, 
    {
        id: 'vinegar',
    },
    {
        id: 'honey',
    },
    {
        id: 'syrup',
    },
    {
        id: 'oil-seeds',
    },
    {
        id: 'mushroom',
    },
    {
        id: 'broth',
    },
    {
        id: 'water',
    },
];

export const createCategoriesList = (withAny) => {
    const result = [];
    PRODUCT_CATEGORIES_LIST.forEach(it => result.push({id: it.id, name: i18n.get(`product.scheme.formGroup.${it.id}`)}));
    withAny ? result.push({id: 'any', name: i18n.get(`product.scheme.formGroup.any`)}) : null;
    return result;
};

export const createFiltersListProductCategories = (withAny, filterGroupName) => {
    const result = [];
    PRODUCT_CATEGORIES_LIST.forEach(it => result.push({
        filterTitle: i18n.get(`product.scheme.formGroup.${it.id}`),
        filterName: it.id,
        filterType: FILTER_TYPE_CHECKBOX,
        variants: [],
        value: false,
        filterGroupName: filterGroupName,
    }));
    if(withAny) {
        result.push({
            filterTitle: i18n.get('product.scheme.formGroup.any'),
            filterName: 'any',
            filterType: FILTER_TYPE_CHECKBOX,
            variants: [],
            value: false,
            filterGroupName: filterGroupName,
        });
    }
    return result;
};

export const findLogicUnits = (value) => {
    if(+value > 1000000) {
        return G_UNIT_NAME;
    }
    if(+value > 1000) {
        return MG_UNIT_NAME;
    }
    return MCG_UNIT_NAME;
};
