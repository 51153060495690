import { i18n } from "../i18n";

export const recipeNameService = {
    getLocalization(localeCode, recipe) {
        if(recipe.names && recipe.names.find(it => it.locale === localeCode)) {
            return recipe.names.find(it => it.locale === localeCode).name;
        }
        return i18n.is_exists(`recipe.item.type.${recipe.recipeType}`, localeCode) ? i18n.get(`recipe.item.type.${recipe.recipeType}`, localeCode) : '';
    },
    getPlaceholder() {
        return i18n.get("app.page.recipe.recipeName");
    },
};
