import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../i18n';
import { ADD_PRODUCT_MACRO_ELEMENTS } from '../helper';

const CreateItemPopupSaveButton = ({ itemCreateService, successRedirectTo }) => {
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();    
    
    const redirectToEditPage = (response) => {
        if(response && response.id) {
            navigate(`${successRedirectTo}/${response.id}/?productTab=${ADD_PRODUCT_MACRO_ELEMENTS}`);
        } else {
            itemCreateService.changeItemCode('');
        }
    };

    const createNewItem = () => {
        if(!isDisabled) {
            itemCreateService.saveNewItem(redirectToEditPage);
        }
    };

    const changeButtonState = () => {
        setIsDisabled(itemCreateService.checkCodeValidation() !== '');
    };

    useEffect(() => {
        itemCreateService.registerObserver(changeButtonState);
        return () => itemCreateService.unRegisterObserver(changeButtonState);
    }, []);

    return (
        <button 
            className={`block__button button ${!isDisabled ? '' : 'button_disabled'}`}
            onClick={createNewItem}
        >
            <span className="button__content">{i18n.get(`app.page.product.modal.create`)}</span>
        </button>
    );
};

export default CreateItemPopupSaveButton;
