import { sortByDate } from "../components/helper";
import { FILTER_TYPE_CHECKBOX } from "../constant";
import { i18n } from "../i18n";
import { PHRASE_URL_PART, PREFERENCE_URL, getFetchRequest, postFetchRequest } from "../store/requests";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";
import {createListOfFilters} from "./preferenceUtil";

const findSearchValueInProperty = (property, titleFilter) => {
    if(i18n.get(`preference.scheme.preferenceType.${property.preferenceType}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    if(i18n.get(`recipe.scheme.recipeType.${property.preferenceItemCode}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    if(i18n.get(`preference.scheme.when.${property.when}`).toLowerCase().includes(titleFilter)) {
        return true;
    }
    return false;
};

const parseFilterValue = (filterValue) => {
    if (filterValue === "true") return true;
    if (filterValue === "false") return false;
    return filterValue;
};

export const preferencesStorage = {
    preferencesList: [],
    filtersList: [],
    searchValue: '',
    observersList: [],
    possibleFilters: [],
    selectedItems: [],
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([PREFERENCE_URL]), (response) => {
                this.preferencesList = response.sort(sortByDate);
                callback && callback();
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getItems() {
        let filteredList = [...this.preferencesList];
        if(this.searchValue) {
            filteredList = this.preferencesList.filter(it => findSearchValueInProperty(it, this.searchValue));
        }
        if(this.filtersList.length) {
            this.filtersList.forEach(filter => {
                const filterValue = parseFilterValue(filter.filterName.split('.')[1]);
                filteredList = filteredList.filter(it => it[filter.filterGroupName] === filterValue);
            });
        }
        return filteredList;
    },
    getInitialFilters(searchParams) {
        const filtersList = [];
        searchParams.forEach((value, key) => {
            const currentFilter = this.possibleFilters.find(it => it.filterName === key);
            if(currentFilter.filterType === FILTER_TYPE_CHECKBOX && value === 'true') {
                currentFilter.value = value;
                filtersList.push(currentFilter);
            }
        });
        this.filtersList = filtersList;
    },
    getPossibleFilters() {
        this.possibleFilters = createListOfFilters();
        return this.possibleFilters;
    },
    getCurrentFilters() {
        return this.filtersList;
    },
    getCurrentFiltersByGroup(groupName) {
        return this.possibleFilters.filter(it => it.filterGroupName === groupName);
    },
    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.notifyFiltersChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    setCheckedFilterValues(filterName, selectedKeys) {
        const currentFilter = this.filtersList.find(it => it.filterName === filterName);
        if(currentFilter) {
            currentFilter.value = selectedKeys;
        } else {
            const possibleFilter = this.possibleFilters.find(it => it.filterName === filterName);
            possibleFilter.value = selectedKeys;
            this.filtersList.push(possibleFilter);
        }
        this.filtersList = this.filtersList.filter(it => 
            it.filterType !== FILTER_TYPE_CHECKBOX || (it.filterType === FILTER_TYPE_CHECKBOX && it.value == true));
    },
    setNumberFilterValue() {
    },
    cleanFilterValues() {
        this.filtersList = [];
        this.possibleFilters.forEach((it, index) => {
            if(it.filterType === FILTER_TYPE_CHECKBOX) {
                this.possibleFilters[index].value = false;
            }
        });
        this.notifyFiltersChangingObservers();
    },
    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },
    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getSelectedPreferencesList() {
        return this.selectedItems;
    },
    changeSelectedPreferencesList(preferenceId, cleanAll) {
        if(preferenceId) {
            if(this.selectedItems.length && this.selectedItems.find(it => it.id === preferenceId)) {
                this.selectedItems = this.selectedItems.filter(it => it.id !== preferenceId);
            } else {
                if(this.preferencesList.find(it => it.id === preferenceId)) {
                    this.selectedItems.push(this.preferencesList.find(it => it.id === preferenceId));
                } else {
                    this.selectedItems.push(this.getPreferencePhraseById(preferenceId));
                }
            }
        } else {
            if((this.preferencesList.length === this.selectedItems.length && this.selectedItems.length !== 0) || cleanAll) {
                this.selectedItems = []; 
            } else {
                this.selectedItems = this.preferencesList;
            }
        }
        this.notifyFiltersChangingObservers();
    },
    generatePreferencePhrases(preferenceId) {
        postFetchRequest(makeUrl([PREFERENCE_URL,preferenceId, PHRASE_URL_PART]), null, successCallbackEmpty, errorCallback);
    },
    getPreferencePhrases(preferenceId, isRecreate) {
        const preference = this.preferencesList.find(it => it.id === preferenceId);
        if(preference.phrase && !isRecreate) {
            return preference.phrase;
        } else {
            getFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART]), (response) => {
                preference.phrase = response;
                this.notifyFiltersChangingObservers();
            }, errorCallback);
            return [];
        }
    },
    getPreferencePhrasesCount(preferenceId, callback) {
        getFetchRequest(makeUrl([PREFERENCE_URL, preferenceId, PHRASE_URL_PART, '?onlyCount=true']), (response) => {
            this.preferencesList.forEach(it => it.id === preferenceId ? it.count = response.count : null);
            callback(response);
        }, errorCallback);
    },
    getById(preferenceId) {
        return this.preferencesList.find(it => it.id === preferenceId);
    },
    clearService() {
        this.preferencesList = [];
        this.notifyFiltersChangingObservers();
    },
    reloadPagesNumber() {
    },
    changeDeletedItemsView() {
    },
    changeValidItemsView() {
    },
    getPathForPhrasesPage(preference) {
        let result = '/preference/phrase/list?';
        if (preference.include) {
            result += "include.true=true&";
        } else {
            result += "include.false=true&";
        }
        result += `preferenceType.${preference.preferenceType}=true&when.${preference.when}=true`;
        return result;
    },
};
