import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { POPUP_KEY_ADD_PROPERTY } from '../../../constant';
import Modal from '../../Modal';
import { MODAL_SMALL_SIZE } from '../../helper';
import { propertiesStorage } from '../../../services/propertiesStorage';
import { productSaveService } from '../../../services/productSaveService';
import { propertiesService } from '../../../services/propertiesService';
import { i18n } from '../../../i18n';
import Select from '../../sharedComponents/Select';
import { popupOpenService } from '../../../services/popupOpenService';

const createFilteredList = (fullPropertiesList, productProperties) => {
    return fullPropertiesList.filter(it => productProperties.findIndex(item => item.propertyName === it.propertyName) === -1);
};

const transformProperiesListToSearchValueList = (array, openedTab) => {
    const result = [];
    const filteredArray = array.filter(it => it.categoryName === openedTab);
    for(let property of filteredArray) {
        result.push({name: i18n.get(propertiesService.getPropertyNamei18nCode(property)), code: property.propertyName, id: property.id});
    }
    return result;
};

const CreateNewPropertyModal = ({handleClose, newPropertyCode, setNewPropertyCode, handleCreateNewProperty, propertiesEnumList, openedTab}) => {
    const [propertiesList, setPropertiesList] = useState({fullList: transformProperiesListToSearchValueList(propertiesEnumList, openedTab), activeList:[]});
    
    const handleCloseList = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setNewPropertyCode('');
        setPropertiesList({...propertiesList, activeList:[]});  
    };

    useEffect(() => {
        if(propertiesList.firstRender) {
            setPropertiesList({...propertiesList, firstRender: false});
        } else {
            const activeList = propertiesList.fullList.filter(it => it.name.toLowerCase().includes(newPropertyCode.toLowerCase()));
            setPropertiesList({...propertiesList, activeList});
        }
    }, [newPropertyCode]);

    return (
        <div onClick={handleCloseList}>
            <h2 className="modal__title block__title-h3 product__modal-title">{i18n.get(`app.page.product.productAddProperty.title`)}</h2>
            <label className={`block__text product__form-label product__modal-property product__modal-label ${propertiesList.activeList.length ? 'product__modal-label_active' : ''}`}>
                <Select listOfOptions={propertiesList} setListOfOptions={setPropertiesList} withSearch={true} activeOptionList={[]}
                    searchInputValue={newPropertyCode} setSearchInputValue={setNewPropertyCode}/>
            </label>
            <div className="modal__button-group">
                <button className="block__button button button_outline" onClick={handleClose}>
                    <span className="button__content">{i18n.get(`app.page.product.productAddProperty.cancel`)}</span>
                </button>
                <button type="button" className={`block__button button ${newPropertyCode ? '' : 'button_disabled'}`} onClick={handleCreateNewProperty}>
                    <span className="button__content">{i18n.get(`app.page.product.productAddProperty.continue`)}</span>
                </button>
            </div>
        </div>
    );
};

const AddPropertyPopup = () => {
    const [isVisible, setIsVisible] = useState('');
    const [newPropertyCode, setNewPropertyCode] = useState('');
    const [searchParams] = useSearchParams();
    const productTab = searchParams.get('productTab');

    const changePopupVisibility = () => {
        setIsVisible(true);
    };

    const handleCreateNewProperty = () => {
        const resultArray = transformProperiesListToSearchValueList(propertiesStorage.getItems(true), productTab);
        const item = resultArray.find(it => it.name === newPropertyCode);
        productSaveService.addProductProperty(item.code, productTab, item.id);
        setIsVisible(false);
        setNewPropertyCode('');
    };

    const closePopup = () => {
        setIsVisible(false);
        setNewPropertyCode('');
    };

    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_ADD_PROPERTY, changePopupVisibility);
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_ADD_PROPERTY, changePopupVisibility);
    }, []);

    return (
        isVisible ?
            <Modal handleCloseModal={setIsVisible} size={MODAL_SMALL_SIZE}
                content={<CreateNewPropertyModal handleCreateNewProperty={handleCreateNewProperty} setNewPropertyCode={setNewPropertyCode}
                    handleClose={closePopup} newPropertyCode={newPropertyCode} openedTab={productTab}
                    propertiesEnumList={createFilteredList(propertiesStorage.getItems(true), Object.values(productSaveService.getProduct().properties))}
                />} 
            />
            :
            null
    );
};

export default AddPropertyPopup;
