import React from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import { preferenceUpdateCerealMixService } from '../../services/preferenceUpdateCerealMixService';
import {PREFERENCE_TYPES} from "../../constant";

const createListOfOptions = () => {
    return PREFERENCE_TYPES.map(it => {
        return {
            id: it,
            name: i18n.get(`preference.scheme.preferenceType.${it}`),
        };
    });
};

const findPreferenceTitle = (itemSaveService) => {
    if(itemSaveService.getPreference().preferenceType) {
        return i18n.get(`preference.scheme.preferenceType.${itemSaveService.getPreference().preferenceType}`);
    } else {
        return '';
    }
};

const PreferenceStructureType = ({ itemSaveService }) => {
    const updatePreferenceValue = (value) => {
        itemSaveService.updatePreferenceProperty('preferenceType', value);
        preferenceUpdateCerealMixService.changeIngredientType(value);
    };

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.preferenceType')}</span>
            <SelectHandler 
                selectedValue={findPreferenceTitle(itemSaveService)} 
                optionsList={createListOfOptions()} 
                sendSelectedValue={updatePreferenceValue}
            /> 
        </label> 
    );
};

export default PreferenceStructureType;
