import React, { useState, useEffect } from 'react';
import RecipeListItem from './RecipeListItem';
import { recipesStorage } from '../../services/recipesStorage';
import { ErrorHandler } from '../ErrorHandler';
import { i18n } from '../../i18n';

const RecipesList = () => {
    const [recipesListIsChanged, setRecipesListIsChanged] = useState(false);

    const changeRecipesList = () => {
        setRecipesListIsChanged(prev => !prev);
    };

    useEffect(() => {
        recipesStorage.registerFiltersChangingObserver(changeRecipesList);
        return () => recipesStorage.unRegisterFiltersChangingObserver(changeRecipesList);
    }, []);

    return (
        recipesStorage.getItems().length ? 
            <ul className="recipe__list">
                {recipesStorage.getItems().map(it => (
                    <li key={it.id + recipesListIsChanged} className="recipe__list-item">
                        <RecipeListItem recipeId={it.id} recipe={it} />
                    </li>
                ))}
            </ul>
            :
            <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')} />
    );
};

export default RecipesList;
