import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PenIcon } from '../../appearence/icons/pen_icon';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';

const PreferenceGroupsActionsEditIcon = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const navigate = useNavigate();

    const changeIsDisabled = () => {
        setIsDisabled(preferencesGroupsStorage.getSelectedList().length !== 1);
    };

    useEffect(() => {
        preferencesGroupsStorage.registerFiltersChangingObserver(changeIsDisabled);
        return () => preferencesGroupsStorage.unRegisterFiltersChangingObserver(changeIsDisabled);
    }, []);

    const navigateOnEditPage = () => {
        if(!isDisabled) {
            navigate(`/preference/group/${preferencesGroupsStorage.getSelectedList()[0].id}`);
        }
    };

    return (
        <li onClick={navigateOnEditPage}>
            <PenIcon handleClick={navigateOnEditPage} nameOfClass={`preference__groups-icon
                ${!isDisabled ? '' : 'preference__groups-icon_disabled'}`
            } />
        </li>
    );
};

export default PreferenceGroupsActionsEditIcon;
