import React from 'react';
import { getFetchRequest, PREFERENCE_URL } from '../../store/requests';
import { i18n } from '../../i18n';
import {errorCallback, makeUrl} from "../../util";

const DownloadCSVFileButton = () => {
    const downloadCSVFile = () => {
        getFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, '/csv/']), async (response) => {
            const blob = await response.blob();
            const newBlob = new Blob([blob]);
            const url = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${i18n.APP_LOCALE}.csv`,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }, errorCallback, true);
    };

    return (
        <button className='button button_outline preference__download' onClick={downloadCSVFile}>
            <span className='button__content'>{i18n.get('app.page.preference.downloadCSV')}</span>
        </button>
    );
};

export default DownloadCSVFileButton;
