import React from 'react';
import { i18n } from '../../../i18n';
import { propertiesService } from '../../../services/propertiesService';
import { getPropertyDataById, productSaveService } from '../../../services/productSaveService';
import ProductPropertyNumberValue from './ProductPropertyNumberValue';
import FormsHandler from '../../formStructure/FormsHandler';
import { createFormsPropertyService } from '../../../services/formsPropertyService';
import { PROPERTY_TYPE_BOOLEAN, PROPERTY_TYPE_NUMBER, PROPERTY_TYPE_SELECT, PROPERTY_TYPE_STRING } from '../../../constant';
import ProductPropertyInput from './ProductPropertyInput';
import ProductPropertySelect from './ProductPropertySelect';
import ProductPropertyBoolean from './ProductPropertyBoolean';
import SourcesHandler from '../../sourcesStructure/SourcesHandler';
import { createSelectPropertyService } from '../../../services/productSelectService';
import DeleteProductPropertyIcon from './DeleteProductPropertyIcon';

const ProductPropertyItem = ({ propertyId }) => {
    const findPropertyType = (propertyType) => {
        switch (propertyType) {
        case PROPERTY_TYPE_NUMBER: {
            return (
                <div className="product__form-block">
                    <ProductPropertyNumberValue 
                        propertyId={propertyId} 
                        fieldName={'minValue'} 
                        fieldValue={getPropertyDataById(propertyId).minValue}
                        itemSaveService={productSaveService} />
                    <ProductPropertyNumberValue 
                        propertyId={propertyId} 
                        fieldName={'maxValue'} 
                        fieldValue={getPropertyDataById(propertyId).maxValue}
                        itemSaveService={productSaveService} />
                </div>
            );
        }
        case PROPERTY_TYPE_STRING: {
            return <ProductPropertyInput propertyId={propertyId} itemSaveService={productSaveService} />;
        }
        case PROPERTY_TYPE_BOOLEAN: {
            return <ProductPropertyBoolean propertyId={propertyId} itemSaveService={productSaveService} />;
        }
        case PROPERTY_TYPE_SELECT: {
            return <ProductPropertySelect selectWithTokensManager={createSelectPropertyService(propertyId)}/>;
        }
        default: {
            return null;
        }}
    };

    return (
        getPropertyDataById(propertyId).propertyType === PROPERTY_TYPE_BOOLEAN ?
            findPropertyType(getPropertyDataById(propertyId).propertyType)
            :
            <>
                <h4 className='product__add-subtitle'>
                    <span className="block__content product__add-text">
                        {i18n.get(propertiesService.getPropertyNamei18nCode(getPropertyDataById(propertyId)))}
                    </span>
                    <DeleteProductPropertyIcon propertyId={propertyId} />
                </h4>
                {findPropertyType(getPropertyDataById(propertyId).propertyType)}
                <FormsHandler selectWithTokensManager={createFormsPropertyService(propertyId)} />
                <SourcesHandler propertyId={propertyId} />
            </>
    );
};

export default ProductPropertyItem;
