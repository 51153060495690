import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PAGE_TAB_NAME_NEW } from '../../constant';
import PageTitleAsBreadCrumbs from '../sharedComponents/PageTitleAsBreadCrumbs';
import LastUpdateTime from '../sharedComponents/LastUpdateTime';
import PreferenceStructureIncluded from './PreferenceStructureIncluded';
import PreferenceStructureType from './PreferenceStructureType';
import PreferenceStructureItemCode from './PreferenceStructureItemCode';
import PreferenceStructureWhen from './PreferenceStructureWhen';
import PreferenceStructureItemHowOften from './PreferenceStructureItemHowOften';
import PreferenceStructurePhrase from './PreferenceStructurePhrase';
import { preferencesGroupsStorage } from '../../services/preferencesGroupsStorage';
import { productsStorage } from '../../services/productsStorage';
import { initializePhraseSaveService, phraseSaveService } from '../../services/phraseSaveService';

const PhraseItemEdit = () => {
    const { id } = useParams();
    const [phraseIsReady, setPhraseIsReady] = useState(false);

    const changePreferenceReadyState = () => {
        setPhraseIsReady(phraseSaveService.getPhraseData().include !== undefined);
    };

    useEffect(() => {
        if(id !== PAGE_TAB_NAME_NEW) {
            initializePhraseSaveService(id);
        }
        productsStorage.loadItems(() => {});
        preferencesGroupsStorage.loadItems();
        phraseSaveService.registerItemUpdateObserver(changePreferenceReadyState);
        return () => {
            phraseSaveService.unRegisterItemUpdateObserver(changePreferenceReadyState);
            phraseSaveService.cleanService();
        };
    }, [id]);

    return (
        phraseIsReady ? 
            <>
                <PageTitleAsBreadCrumbs />
                <article className="preference__article preference__article_full-width preference__article-add">
                    <form className="preference__form" key={phraseIsReady}>
                        <LastUpdateTime itemSaveService={phraseSaveService} />
                        <PreferenceStructureIncluded itemSaveService={phraseSaveService} />
                        <PreferenceStructureItemHowOften itemSaveService={phraseSaveService} />
                        <PreferenceStructureType itemSaveService={phraseSaveService} />
                        <PreferenceStructureItemCode itemSaveService={phraseSaveService} />
                        <PreferenceStructureWhen itemSaveService={phraseSaveService} />
                        <PreferenceStructurePhrase itemSaveService={phraseSaveService} />
                    </form>
                </article>
            </>
            :
            null
    );
};

export default PhraseItemEdit;
