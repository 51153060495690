import { checkItemLocaleCode } from "../components/helper";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import {
    RECIPE_CHARACTERISTIC_SOURCE,
    RECIPE_CHARACTERISTIC_TASTE,
    RECIPE_CHARACTERISTIC_TASTES,
} from "../constant";
import { i18n } from "../i18n";
import { RECIPES_URL, deleteFetchRequest, getFetchRequest, postFetchRequest } from "../store/requests";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";

const removeElementFromArray = (elements, conditionBeInArray) => {
    const result = [];
    elements.forEach((it) => {
        if (conditionBeInArray(it)) {
            result.push(it);
        }
    });
    return result;
};

const isRecipeCharacteristicActive = (recipe, characteristicCategory, characteristicValue) => {
    if (recipe && recipe.characteristics) {
        return !(recipe.characteristics.find(it => it.value === characteristicValue && it.category === characteristicCategory) === undefined);
    }
    return false;
};

export const recipeSaveService = {
    currentRecipe: {},
    lastRecipeUpdateTime: null,
    recipeUpdateObservers: [],
    loadRecipe(recipeId, callback) {
        try {
            getFetchRequest(makeUrl([RECIPES_URL, recipeId, '/']), (response) => {
                this.currentRecipe = response;
                getFetchRequest(makeUrl([RECIPES_URL, recipeId, '/ingredient/']), (result) => {
                    this.currentRecipe.ingredient = result;
                    callback();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getRecipe() {
        return this.currentRecipe;
    },
    getTasteCharacteristics() {
        return RECIPE_CHARACTERISTIC_TASTES.map(it => {
            return {category: RECIPE_CHARACTERISTIC_TASTE, value: it, code: it, isActive: isRecipeCharacteristicActive(this.currentRecipe, RECIPE_CHARACTERISTIC_TASTE, it)};
        });
    },
    getCurrentRecipeSource() {
        return this.currentRecipe.characteristics?.find(it => it.category === RECIPE_CHARACTERISTIC_SOURCE);
    },
    getDateLastUpdate() {
        return this.lastRecipeUpdateTime;
    },
    registerItemUpdateObserver(observer) {
        this.recipeUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.recipeUpdateObservers = this.recipeUpdateObservers.filter(productUpdateFunction => productUpdateFunction !== observer);
    },
    notifyRecipeUpdateObservers() {
        this.recipeUpdateObservers.forEach(productUpdateFunction => productUpdateFunction(this.currentRecipe));
    },
    updateRecipeName(locale, value) {
        postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/name/']),
            {
                localizedName: value,
                locale: locale,
            },
            () => {
                if(this.currentRecipe.names.length && this.currentRecipe.names.find(it => it.locale === locale)) {
                    this.currentRecipe.names.forEach(it => {
                        if(it.locale === locale) {
                            it.name = value;
                        }
                    });
                } else {
                    this.currentRecipe.names.push({
                        locale: locale,
                        name: value,
                    });
                }
                this.lastRecipeUpdateTime = Date.now();
            }, errorCallback, true);
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(`recipe.item.type.${this.currentRecipe.recipeType}`, value, locale);
            if (this.currentRecipe.locales && this.currentRecipe.locales.length) {
                const index = this.currentRecipe.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentRecipe.locales[index].localeValue = value
                    :
                    this.currentRecipe.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentRecipe.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentRecipe.recipeType)) {
                updateValueSevice.updateLocaleValue(locale, `recipe.item.type.${this.currentRecipe.recipeType}`, () => {
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateSourceCharacteristic(characteristicValue, characteristicCategory) {
        try {
            const currentSources = this.currentRecipe.characteristics.filter(it => it.category === characteristicCategory);
            if (currentSources && currentSources.length > 0) {
                currentSources.forEach(characteristic => {
                    deleteFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, `/characteristic/${characteristic.id}/`]),
                        successCallbackEmpty, errorCallback);
                });
            }
            postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/characteristic/']),
                {
                    category: characteristicCategory,
                    value: characteristicValue,
                },
                (response) => {
                    if(!this.currentRecipe.characteristics.find(it => it.category === characteristicCategory)) {
                        this.currentRecipe.characteristics.push({
                            "category": characteristicCategory,
                            "value": response.value,
                            "id": response.id,
                        });
                    } else {
                        this.currentRecipe.characteristics.forEach(it => {
                            if(it.category === characteristicCategory) {
                                it.value = response.value;
                                it.id = response.id;
                            }
                        });
                    }
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    updateCharacteristic(characteristicValue, characteristicCategory) {
        try {
            const currentCharacteristic = this.currentRecipe.characteristics.find(it => it.value === characteristicValue && it.category === characteristicCategory);
            if (currentCharacteristic === undefined) {
                postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/characteristic/']), {
                    category: characteristicCategory,
                    value: characteristicValue,
                },
                (response) => {
                    this.currentRecipe.characteristics.push(response);
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
            } else {
                deleteFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, `/characteristic/${currentCharacteristic.id}/`]),
                    (response) => {
                        this.currentRecipe.characteristics = removeElementFromArray(this.currentRecipe.characteristics, (it) => {
                            if (it.id === currentCharacteristic.id) return false;
                            return true;
                        });
                        this.lastRecipeUpdateTime = Date.now();
                        this.notifyRecipeUpdateObservers();
                    }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    addIngredient(ingredient) {
        try {
            postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/']), ingredient, 
                (response) => {
                    this.currentRecipe.ingredient.push(response);
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    deleteIngredient(ingredientId) {
        try {
            this.currentRecipe.ingredient = this.currentRecipe.ingredient.filter(it => it.id !== ingredientId);
            deleteFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', ingredientId, '/']), successCallbackEmpty, errorCallback);
            this.lastRecipeUpdateTime = Date.now();
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteIngredientForm(ingredientId, formCode) {
        try {
            const currentIngredient = this.currentRecipe.ingredient.find(it => it.id === ingredientId);
            currentIngredient.ingredientParts[0].ingredientForms = currentIngredient.ingredientParts[0].ingredientForms.filter(it => it !== formCode);
            postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', currentIngredient.id, '/']), currentIngredient, successCallbackEmpty, errorCallback, true);
            this.lastRecipeUpdateTime = Date.now();
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    addIngredientForm(ingredientId, form) {
        console.log("------------------------addIngredientForm");
        console.log(ingredientId);
        console.log(form);
        try {
            console.log(this.currentRecipe);
            console.log(this.currentRecipe.ingredient);
            const currentIngredient = this.currentRecipe.ingredient.find(it => it.id === ingredientId);
            currentIngredient.ingredientParts[0].ingredientForms.push(form.code);
            updateValueSevice.updateItemValue(() => {
                postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', ingredientId, '/']), currentIngredient, () => {
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback, true);
            });
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updateRecipeType(value) {
        this.currentRecipe.recipeType = value;
        postFetchRequest(
            makeUrl([RECIPES_URL, this.currentRecipe.id, '/']),
            {recipeType: value, takeAsIs: this.currentRecipe.takeAsIs},
            successCallbackEmpty,
            errorCallback,
        );
        this.lastRecipeUpdateTime = Date.now();
        this.notifyRecipeUpdateObservers();
    },
    updateTakeAsIs() {
        this.currentRecipe.takeAsIs = !this.currentRecipe.takeAsIs;
        postFetchRequest(
            makeUrl([RECIPES_URL, this.currentRecipe.id, '/']),
            {recipeType: this.currentRecipe.recipeType, takeAsIs: this.currentRecipe.takeAsIs},
            successCallbackEmpty,
            errorCallback,
            true,
        );
        this.lastRecipeUpdateTime = Date.now();
        this.notifyRecipeUpdateObservers();
    },
    cleanService() {
        this.currentRecipe = {};
    },
};
