import React from 'react';

const CustomCheckbox = ({className, checked, handleChange, values = null}) => {
    const preparedData = values ? Object.values(values) : null;
    return (
        <label className={`block__content checkbox__container ${className ? className : ''}`}>
            <input className="checkbox__item" type="checkbox" checked={checked} onChange={values ? () => handleChange(...preparedData) : handleChange}/>
            <span className="checkbox__checkmark"></span>
        </label>
    );
};

export default CustomCheckbox;
