import { PRODUCT_URL, getFetchRequest } from "../store/requests";
import { productsStorage } from "./productsStorage";
import {errorCallback, makeUrl} from "../util";

export const productDeleteService = {
    getWhereItemIsUsed(itemId, callback) {
        getFetchRequest(makeUrl([PRODUCT_URL, itemId, '/used/']), callback, errorCallback);
    },
    getTranslationCode() {
        return 'app.page.product.hint';
    },
    checkIsDeletedItem(itemId) {
        return productsStorage.getById(itemId).isDeleted;
    },
};
