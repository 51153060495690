import React, { useState, useEffect, useRef, useCallback } from 'react';
import {MOUSE_DOWN_EVENT, RECIPE_INGREDIENT_TYPE_CEREAL_MIX} from '../../constant';
import { ChevronIcon } from '../../appearence/icons/chevron_icon';
import SelectWithTokensActiveItem from '../sharedComponents/SelectWithTokensActiveItem';
import SelectWithTokensInput from '../sharedComponents/SelectWithTokensInput';
import SelectWithTokensSelectList from '../sharedComponents/SelectWithTokensSelectList';
import { productsStorage } from '../../services/productsStorage';
import { i18n } from '../../i18n';
import { PlusIcon } from '../../appearence/icons/plus_icon';
import CreateCerealMixBlock from '../preferences/CreateCerealMixBlock';
import { preferenceCerealMixSelectService } from '../../services/preferenceCerealMixSelectService';

const checkSelectedForms = (selectWithTokensManager) => {
    return selectWithTokensManager.getCurrentItems() && selectWithTokensManager.getCurrentItems().length;
};

const IngredientHandler = ({ selectWithTokensManager }) => {
    const [selectListIsOpen, setSelectListIsOpen] = useState(false);
    const [selectedListLength, setSelectedListLength] = useState();
    const [addNewCerealMixIsOpen, setAddNewCerealMixIsOpen] = useState(false);
    const wrapperRef = useRef();

    const openItemsList = () => {
        setSelectListIsOpen(true);
    };

    const handleClickOutside = useCallback((event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setSelectListIsOpen(false);
        }
    }, []);

    const changeSelected = () => {
        setSelectedListLength(selectWithTokensManager.getCurrentItems().length);
    };

    useEffect(() => {
        productsStorage.loadItems(() => {});
        document.addEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
        preferenceCerealMixSelectService.loadItems();
        selectWithTokensManager.registerItemsChangingObserver(changeSelected);
        selectWithTokensManager.loadItems();
        return () => {
            document.removeEventListener(MOUSE_DOWN_EVENT, handleClickOutside);
            selectWithTokensManager.unRegisterItemsChangingObserver(changeSelected);
        };
    }, []);

    const changeCerealMixBlockState = (value) => {
        setAddNewCerealMixIsOpen(value);
        selectWithTokensManager.deleteItem('', true);
    };

    return (
        <>
            <label className="block__text product__form-label recipe__ingredient">
                {selectWithTokensManager.getLabelValue && selectWithTokensManager.getLabelValue() ? 
                    <span className="product__form-label_text">
                        {selectWithTokensManager.getLabelValue()}
                    </span>
                    :
                    null}
                <div ref={wrapperRef}>
                    <div className="product__forms" onClick={openItemsList} >
                        {checkSelectedForms(selectWithTokensManager) && selectedListLength > 0 ? 
                            <ul className={`product__forms-list ${selectWithTokensManager.getCurrentItems().length === 0 ? 'product__forms-list_full-width' : ''}`}>
                                {selectWithTokensManager.getCurrentItems().map((it, index) => (
                                    <React.Fragment key={it.itemId + 'ingredient' + index}>
                                        <SelectWithTokensActiveItem 
                                            selectItem={it}
                                            removeCallback={selectWithTokensManager}
                                        />
                                    </React.Fragment>
                                ))}
                                    
                            </ul>
                            :
                            null}
                        {selectListIsOpen || selectWithTokensManager.getCurrentItems().length === 0 ? 
                            <SelectWithTokensInput selectWithTokensManager={
                                selectWithTokensManager.getIngredientType() === RECIPE_INGREDIENT_TYPE_CEREAL_MIX && !addNewCerealMixIsOpen ?
                                    preferenceCerealMixSelectService 
                                    : 
                                    selectWithTokensManager
                            } />
                            :
                            null}
                        <ChevronIcon
                            nameOfClass={`product__forms-icon ${selectListIsOpen ? 'product__forms-icon_active' : ''}`} />
                    </div>
                    {selectListIsOpen ? 
                        <div className="product__forms-dropdown product__select-dropdown">
                            {addNewCerealMixIsOpen && selectWithTokensManager.getIngredientType() === RECIPE_INGREDIENT_TYPE_CEREAL_MIX ?
                                <CreateCerealMixBlock closeCallback={changeCerealMixBlockState} selectManager={selectWithTokensManager} />
                                :
                                <>
                                    <SelectWithTokensSelectList 
                                        selectWithTokensManager={selectWithTokensManager.getIngredientType() === RECIPE_INGREDIENT_TYPE_CEREAL_MIX ? preferenceCerealMixSelectService : selectWithTokensManager}
                                        include={true}/>
                                    {selectWithTokensManager.getIngredientType() === RECIPE_INGREDIENT_TYPE_CEREAL_MIX ?
                                        <div className="block__text preference__mix" onClick={() => changeCerealMixBlockState(true)}>
                                            <span className="button__content">{i18n.get("app.page.preference.addCerealMix")}</span>
                                            <PlusIcon nameOfClass="preference__mix-plus" />
                                        </div>
                                        :
                                        null}
                                </>}
                        </div>
                        :
                        null}
                </div>
            </label>
        </>
    );
};

export default IngredientHandler;
