import {GET_APP_ROLES, getFetchRequest, postFetchRequest} from "../store/requests";
import {errorCallback, makeUrl} from "../util";

export const rolesStorage = {
    roles: [],
    observersList: [],
    loadItems() {
        this.cleanStorage();
        try {
            getFetchRequest(makeUrl([GET_APP_ROLES]), (response) => {
                this.roles = response;
                this.notifyRolesChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getRoles() {
        return this.roles;
    },
    cleanStorage() {
        this.roles = [];
    },
    updateRolePermission(roleData) {
        try {
            postFetchRequest(makeUrl([GET_APP_ROLES, roleData.id, '/']),
                roleData,
                (response) => {
                    this.loadItems();
                },
                errorCallback,
                true);
        } catch(err) {
            console.log(err);
        }
    },
    registerRolesChangingObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterRolesChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },

    notifyRolesChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
};
