import { POPUP_KEY_CREATE_INGREDIENT } from "../constant";
import { i18n } from "../i18n";
import { productsService } from "./productsService";
import { productsStorage } from "./productsStorage";
import { recipesStorage } from "./recipesStorage";

const transformProductsList = (products) => {
    return products.map(it => {
        return {
            itemId: it.id,
            itemTitle: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true),
            included: true,
            code: it.productCode,
        };
    });
};

const createIngredientString = (ingredientsList) => {
    const result = [];
    ingredientsList.forEach(it => result.push(it.itemId));
    return result.join(',');
};

export const recipeIngredientService = {
    observersList: [],
    selectedProducts: [],
    filter: '',
    searchParams: null,
    setSearchParams: null,
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getPossibleItems() {
        return transformProductsList(productsStorage.getItems(true))
            .filter(it => it.itemTitle.toLowerCase().includes(this.filter.toLowerCase()));
    },
    getCurrentItems() {
        return this.selectedProducts;
    },
    deleteItem(productId, deleteAll) {
        if(deleteAll) {
            this.selectedProducts = [];
            return;
        }
        this.selectedProducts = this.selectedProducts.filter(it => it.itemId !== productId);
        recipesStorage.setCheckedFilterValues('ingredient', createIngredientString(this.selectedProducts));
        this.notifyItemsChangingObservers();
    },
    addItem(productId) {
        if(productId !== 'null') {
            const currentProduct = productsStorage.getById(productId);
            this.selectedProducts.push({itemId: currentProduct.id, 
                itemTitle: productsService.getLocalization(i18n.APP_LOCALE, currentProduct.productCode, true), 
                included: true});
            recipesStorage.setCheckedFilterValues('ingredient', createIngredientString(this.selectedProducts));
            this.notifyItemsChangingObservers();
        }
    },
    loadItems(searchParams) {
        if(searchParams && searchParams.get('ingredient')) {
            for(let productId of searchParams.get('ingredient').split(',')) {
                !this.selectedProducts.find(it => it.itemId === productId) &&
                    this.selectedProducts.push({itemId: productId, 
                        itemTitle: productsService.getLocalization(i18n.APP_LOCALE, productsStorage.getById(productId).productCode, true), 
                        included: true});
            }
            this.notifyItemsChangingObservers();
        }
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.notifyItemsChangingObservers();
    },
    getSearchString() {
        return this.filter;
    },
    getPopupKey() {
        return POPUP_KEY_CREATE_INGREDIENT;
    },
    getButtonTitle() {
        return i18n.get('app.page.recipe.addProduct');
    },
    getIngredientType() {
        return this.ingredientType;
    },
    changeIngredientType() {
        return;
    },
    getLabelValue() {
        return i18n.get('app.page.product.filters.ingredient');
    },
    createSearchParams(searchParams, setSearchParams) {
        this.searchParams = searchParams;
        this.setSearchParams = setSearchParams;
    },
};
