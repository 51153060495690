import React, { useState, useEffect } from 'react';
import { i18n } from '../../i18n';

const SelectWithTokensInput = ({ selectWithTokensManager }) => {
    const [searchValue, setSearchValue] = useState('');

    const changeItemsList = () => {
        setSearchValue(selectWithTokensManager.getSearchString());
    };

    useEffect(() => {
        selectWithTokensManager.registerItemsChangingObserver(changeItemsList);
        return () => selectWithTokensManager.registerItemsChangingObserver(changeItemsList);
    }, [selectWithTokensManager]);

    const setSearchInputValue = (e) => {
        setSearchValue(e.target.value);
        selectWithTokensManager.setSearchFilterValue(e.target.value);
    };

    return (
        <input className={`block__content product__forms-input ${selectWithTokensManager.getCurrentItems().length === 0 ? 'product__forms-input_active' : 0}`}
            value={searchValue} 
            onChange={setSearchInputValue}
            placeholder={selectWithTokensManager.getCurrentItems().length === 0 ? i18n.get("app.page.product.selectFromList") : ''}
        />
    );
};

export default SelectWithTokensInput;
