import React from 'react';
import SearchItemBlock from '../sharedComponents/SearchItemBlock';
import { i18n } from '../../i18n/index';
import PreferencesList from './PreferencesList';
import PreferencePhraseSwitcher from './PreferencePhraseSwitcher';
import { preferencesStorage } from '../../services/preferencesStorage';
import CreateNewPreferenceButton from './CreateNewPreferenceButton';
import DeletePreferencePopup from './DeletePreferencePopup';
import AssignPreferenceToUserPopup from './AssignPreferenceToUserPopup';
import PreferencesListActions from './PreferencesListActions';
import AssignGroupToPreferencePopup from './AssignGroupToPreferencePopup';

const PreferenceItemsTab = () => {
    const handleLoadPreference = () => {
        preferencesStorage.loadItems(() => {});
    };

    return (
        <React.Fragment>
            <DeletePreferencePopup notifyPreferencesList={handleLoadPreference} />
            <AssignPreferenceToUserPopup notifyPreferencesList={handleLoadPreference} />
            <AssignGroupToPreferencePopup notifyPreferencesList={handleLoadPreference} />
            <SearchItemBlock itemsStorageService={preferencesStorage} placeholder={i18n.get('app.page.preference.preferenceSearch')} />
            <CreateNewPreferenceButton />
            <article className="preference__article preference__article_full-width">
                <div className="preference__groups-block">
                    <PreferencePhraseSwitcher pageUrl={'/preference/phrase/list'} itemTitle={i18n.get('app.page.preference.item')} />
                    <PreferencesListActions/>
                </div>
                <PreferencesList />
            </article>
        </React.Fragment>
    );
};

export default PreferenceItemsTab;
