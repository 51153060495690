import { POPUP_KEY_CREATE_INGREDIENT, PREFERENCE_TYPE_CEREAL_MIX } from "../constant";
import { i18n } from "../i18n";
import { formsService } from "./formsService";
import { createPossibleItemCodeValues, preferenceSaveService } from "./preferenceSaveService";
import { productsService } from "./productsService";
import { productsStorage } from "./productsStorage";

const transformProductsList = (formsList) => {
    let resultArray = [];
    formsList.forEach(it => resultArray.push({itemId: it.id, itemTitle: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true), 
        included: true, code: it.productCode}));
    return resultArray;
};

const prepareListOfCodes = (listOfCerealMixes) => {
    const result = [];
    listOfCerealMixes.forEach(it => result.push({itemCode: it.code}));
    return result;
};

const transformItemCodeToList = () => {
    const result = [];
    if(preferenceSaveService.getPreference().preferenceItemCodes && preferenceSaveService.getPreference().preferenceItemCodes.length) {
        preferenceSaveService.getPreference().preferenceItemCodes.forEach(it => 
            result.push({itemId: it.id, itemTitle: productsService.getLocalization(i18n.APP_LOCALE, it.itemCode, true), 
                included: true, code: it.itemCode}));
    }
    return result;
};

export const preferenceUpdateCerealMixService = {
    observersList: [],
    possibleProducts: transformProductsList(productsStorage.getItems(true)),
    selectedProducts: transformItemCodeToList(),
    ingredientType: PREFERENCE_TYPE_CEREAL_MIX,
    filter: '',
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getPossibleItems() {
        this.possibleProducts = transformProductsList(createPossibleItemCodeValues(this.ingredientType));
        if(this.filter) {
            return this.possibleProducts.filter(it => formsService.getLocalization(i18n.APP_LOCALE, it.productCode, true).toLowerCase().includes(this.filter));
        }
        return this.possibleProducts;
    },
    loadItems() {
        this.selectedProducts = transformItemCodeToList();
        this.notifyItemsChangingObservers();
    },
    getCurrentItems() {
        return this.selectedProducts;
    },
    deleteItem(productId, deleteAll) {
        this.selectedProducts = deleteAll ? [] : this.selectedProducts.filter(it => it.itemId !== productId);
        this.notifyItemsChangingObservers();
    },
    addItem(productId) {
        const currentProduct = productsStorage.getById(productId);
        this.selectedProducts.push({itemId: currentProduct.id, code: currentProduct.productCode,
            itemTitle: productsService.getLocalization(i18n.APP_LOCALE, currentProduct.productCode, true), 
            included: true});
        preferenceSaveService.updatePreferenceProperty('preferenceItemCodes', prepareListOfCodes(this.selectedProducts));
        this.notifyItemsChangingObservers();
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    },
    getSearchString() {
        return this.filter;
    },
    getPopupKey() {
        return POPUP_KEY_CREATE_INGREDIENT;
    },
    getButtonTitle() {
        return i18n.get('app.page.recipe.addProduct');
    },
    getIngredientType() {
        return this.ingredientType;
    },
    changeIngredientType(value) {
        this.ingredientType = value;
        this.selectedProducts = [];
        this.notifyItemsChangingObservers();
    },
};
