import { i18n } from "../i18n";

export const recipeNamesService = {
    getLocalization(localeCode, recipe) {
        if(recipe.names && recipe.names.find(it => it.locale === localeCode)) {
            return recipe.names.find(it => it.locale === localeCode).name;
        }
        return '';
    },
    getPlaceholder() {
        return i18n.get("app.page.recipe.recipeName");
    },
};
