import React from "react";

export const PenIcon = ({ nameOfClass, handleClick, value }) => {
    return <svg className={nameOfClass} onClick={(e) => {
        e.stopPropagation();
        handleClick(value);
    }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1831 2.73316L14.2904 4.6257L19.3741 9.70896L21.2668 7.81642C22.2444 6.83887 22.2444 5.25524 21.2668 4.27769L19.726 2.73316C18.7484 1.75561 17.1647 1.75561 16.187 2.73316H16.1831ZM13.4067 5.5094L4.29128 14.628C3.88459 15.0346 3.58739 15.5391 3.42315 16.0904L2.03883 20.7944C1.94107 21.1267 2.03101 21.4826 2.27346 21.725C2.51592 21.9674 2.87177 22.0574 3.20025 21.9635L7.90459 20.5793C8.45597 20.4151 8.96043 20.1179 9.36712 19.7112L18.4903 10.5927L13.4067 5.5094Z" />
    </svg>;
};
