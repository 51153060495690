import React, { useState } from 'react';
import { GET_USER_REQUEST, deleteFetchRequest } from '../store/requests';
import { ArrowIcon } from '../appearence/icons/arrow_icon';
import Modal from './Modal';
import { i18n } from '../i18n';
import { MODAL_SUPER_SMALL_SIZE } from './helper';
import {errorCallback, makeUrl} from "../util";

const DeleteModalContent = ({handleDelete, handleClose}) => (
    <>
        <h2 className="modal__title block__title-h3">{i18n.get('app.page.user.modal.title')}</h2>
        <p className="block__content modal__text">{i18n.get('app.page.user.modal.content')}</p>
        <div className="modal__button-group">
            <button className="block__button button button_outline" onClick={() => handleClose(null)}>{i18n.get('app.page.user.modal.cancel')}</button>
            <button className="block__button button" onClick={handleDelete}>{i18n.get('app.page.user.modal.delete')}</button>
        </div>
    </>
);

const ProfilesTab = ({usersList, setUsersList, UsersTable, setUsersListModalIsOpen}) => {
    const [deleteUser, setDeleteUser] = useState(null);
    const [selectedList, setSelectedList] = useState([]);

    const handleDeleteUser = () => {
        for(let user of selectedList) {
            deleteFetchRequest(makeUrl([GET_USER_REQUEST, user.id, '/']), null, errorCallback);
        }
        const preparedUsersList = usersList.filter(it => selectedList.findIndex(item => item.id === it.id) === -1);
        setUsersList(preparedUsersList);
        setDeleteUser(null);
        setSelectedList([]);
    };

    const handleFindCheckedValue = (userId) => {
        return selectedList.findIndex(it => it.id === userId) > -1;
    };

    const handleChangeSelectedList = (user) => {
        let newData = [];
        if(handleFindCheckedValue(user.id)) {
            newData = selectedList.filter(it => it.id !== user.id);
        } else {
            newData = [...selectedList, user];
        }
        setSelectedList(newData);
    };

    return (
        <>
            {deleteUser ? 
                <Modal content={<DeleteModalContent handleDelete={handleDeleteUser} handleClose={setDeleteUser} />} 
                    handleCloseModal={setDeleteUser} size={MODAL_SUPER_SMALL_SIZE}/>
                :
                null}
            <h1 className="block__title-h3 users__title users__tab-title">
                <ArrowIcon nameOfClass="users__tab-icon" handleClick={setUsersListModalIsOpen} value={false}/>
                <span className="users__tab-text">{i18n.get('app.page.user.residentsData')}</span>
            </h1>
            <article className="users__article users__article_full-width">
                <UsersTable usersList={usersList} isTab={true} handleChange={handleChangeSelectedList} findCheckedValues={handleFindCheckedValue}/>
                <div className="users__tab-buttons">
                    {selectedList.length ? 
                        <button className="block__button button button_outline users__tab-button users__tab-button_margin" onClick={() => setSelectedList([])}>
                            <span className="button__content">{i18n.get('app.page.user.removeSelected')}</span>
                        </button>
                        :
                        null}
                    <button className={`block__button button ${selectedList.length ? "" : "button_disabled"} users__tab-button`} onClick={() => setDeleteUser(selectedList)}
                        disabled={!selectedList.length}>
                        <span className="button__content">{i18n.get('app.page.user.delete')}</span>
                    </button>
                </div>
                
            </article>
        </>
    );
};

export default ProfilesTab;
