import { i18n } from '../i18n';
import { preferencesPhrasesStorage } from './preferencesPhrasesStorage';
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import {
    PREFERENCE_TYPE_CEREAL_MIX,
    PREFERENCE_TYPE_PRODUCT,
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_RECIPE,
    PRODUCT_FORM_GRAIN,
    RECIPE_TYPES,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
    RECIPE_CHARACTERISTIC_TASTE,
} from "../constant";
import { PHRASE_URL_PART, PREFERENCE_URL, getFetchRequest, postFetchRequest } from "../store/requests";
import { productsStorage } from "./productsStorage";
import { propertiesService } from "./propertiesService";
import { propertiesStorage } from "./propertiesStorage";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";
import {productsService} from "./productsService";

const createPhrasePreparedBody = (phrase, howOften) => {
    return {
        "preferenceFrequency": howOften.preferenceFrequency,
        "frequencyPeriod": howOften.frequencyPeriod ? howOften.frequencyPeriod : '0',
        "include": phrase.include,
        "preferenceType": phrase.preferenceType,
        "preferenceItemCodes": phrase.preferenceItemCodes,
        "when": phrase.when,
        "content": phrase.content,
        "locale": phrase.locale,
    };
};

const isGrain = (product) => product.productForms.findIndex(it => it.formGroup === PRODUCT_FORM_GRAIN) > -1;

export const createPossibleItemCodeValues = (preferenceType) => {
    if(preferenceType === PREFERENCE_TYPE_PRODUCT) {
        return productsStorage.getItems(true).map(it => {
            return {
                id: it.productCode,
                name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true),
            };
        });
    } else if (preferenceType === PREFERENCE_TYPE_CEREAL_MIX) {
        return productsStorage.getItems(true)
            .filter(it => isGrain(it))
            .map(it => {
                return {
                    id: it.productCode,
                    name: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true),
                };
            });
    } else if (preferenceType === PREFERENCE_TYPE_RECIPE) {
        return RECIPE_TYPES.map(it => {
            return {
                id: it,
                name: i18n.get(`recipe.item.type.${it}`),
            };
        });
    } else if (preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
        return [
            {
                id: RECIPE_CHARACTERISTIC_TASTE,
                name: i18n.get(`app.page.recipe.recipeCharacteristic.${RECIPE_CHARACTERISTIC_TASTE}`),
            },
        ];
    } else if(preferenceType === PREFERENCE_TYPE_PROPERTY) {
        return propertiesStorage.getItems(true).map(it => {
            return {
                id: it.propertyName,
                name: propertiesService.getLocalization(i18n.APP_LOCALE, it.propertyName, false),
            };
        });
    } else {
        return [];
    }
};

export const phraseSaveService = {
    currentPhrase: {},
    phraseUpdateObservers: [],
    lastPhraseUpdateTime: null,
    phraseId: '',
    howOften: '',
    getPhrases() {
        if(!Object.values(this.currentPhrase).length) {
            this.currentPhrase = preferencesPhrasesStorage.getSelectedList()[0];
        }
        return [this.currentPhrase];
    },
    getPhraseData() {
        return this.currentPhrase;
    },
    updatePreferencePhrases(value) {
        this.currentPhrase.content = value;
        updateValueSevice.updateItemValue(() => {
            postFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, this.phraseId, '/']), 
                {content: value}, successCallbackEmpty, errorCallback, true);
        });
    },
    needToAddNewPhrase() {
        return false;
    },
    loadPhrase() {
        try {
            if(this.phraseId) {
                getFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, this.phraseId, '/']), (response) => {
                    this.currentPhrase = response;
                    this.notifyPreferenceUpdateObservers();
                }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    getPreference() {
        return this.currentPhrase;
    },
    updatePreferenceProperty(propertyKey, value) {
        try {
            this.currentPhrase[propertyKey] = value;  
            this.lastPhraseUpdateTime = Date.now(); 
            if(propertyKey === 'preferenceType') {
                this.currentPhrase.preferenceItemCodes = [];
            }
            if(this.phraseId) {
                this.savePreference();
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    savePreference() {
        try {
            postFetchRequest(makeUrl([PREFERENCE_URL, i18n.APP_LOCALE, PHRASE_URL_PART, this.phraseId, '/']), 
                createPhrasePreparedBody(this.currentPhrase, this.howOften), successCallbackEmpty, errorCallback, true);
        } catch(err) {
            console.log(err);
        }
    },
    getHowOften() {
        return this.howOften;
    },
    updateHowOften(frequencyType, frequencyPeriod) {
        this.howOften = {
            preferenceFrequency: frequencyType,
            frequencyPeriod,
        };
        if(this.phraseId) {
            this.savePreference();
        }
        this.lastPhraseUpdateTime = Date.now();
        this.notifyPreferenceUpdateObservers();
    },
    registerItemUpdateObserver(observer) {
        this.phraseUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.phraseUpdateObservers = this.phraseUpdateObservers.filter(preferenceUpdateFunction => preferenceUpdateFunction !== observer);
    },
    notifyPreferenceUpdateObservers() {
        this.phraseUpdateObservers.forEach(preferenceUpdateObserver => preferenceUpdateObserver());
    },
    getDateLastUpdate() {
        return this.lastPhraseUpdateTime;
    },
    cleanService() {
        this.currentPhrase = {};
        this.howOften = '';
    },
    getPossibleCodeValues(preferenceType = null) {
        if(this.itemCode) {
            return createPossibleItemCodeValues(this.currentPhrase.preferenceType).filter(it => it.name.includes(this.itemCode));
        }
        return createPossibleItemCodeValues(this.currentPhrase.preferenceType ? this.currentPhrase.preferenceType : preferenceType);
    },
    updatePreferencePropertyValue(key, value) {
        this.currentPhrase.preferenceItemCodes[0][key] = value;
        this.lastPhraseUpdateTime = Date.now(); 
        if(this.phraseId) {
            this.savePreference();
        }
        this.notifyPreferenceUpdateObservers();
    },
    getProperty() {
        return this.currentPhrase.preferenceItemCodes[0];
    },
    updateProductProperty(propertyId, fieldName, value) {
        this.updatePreferencePropertyValue(fieldName, value);
    },
    updatePropertyValueField(propertyValue) {
        try {
            if(!this.getProperty().value) {
                this.getProperty.value = propertyValue;
                this.updatePreferencePropertyValue('value', propertyValue);
            }
            this.notifyPreferenceUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updateValueLocalization(locale, value, code) {
        try {
            i18n.update(`product.scheme.property.value.${code}`, value, locale);
            updateValueSevice.updateLocaleValue(locale, `product.scheme.property.value.${code}`, () => {});
        } catch(err) {
            console.log(err);
        }
    },
};

export const initializePhraseSaveService = (phraseId) => {
    phraseSaveService.phraseId = phraseId;
    phraseSaveService.loadPhrase();
};
