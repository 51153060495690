import { GET_CURRENT_USER_REQUEST, getFetchRequest } from "../../store/requests";
import {makeUrl} from "../../util";
import {CHAPCHE_WEBSOCKET_URL} from "../../constant";

const CONNECTION_INTERVAL_STEP = 200;
export const WEBSOCKET_EVENT_TYPE_MEAL = "/meal";
export const WEBSOCKET_EVENT_TYPE_MEAL_INGREDIENT = "/meal/ingredient";

const checkConnection = (ws) => {
    return !!ws && ws.readyState === WebSocket.OPEN;
};

const getConnectionIntervalTime = (count) => (
    (count + 1) * CONNECTION_INTERVAL_STEP
);

class WebSocketConnectionService {
    coreObservers = [];
    ws = null;
    timeout = null;

    constructor () {
    }
    
    createWSConnection(onOpenCallback, count) {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            if(!checkConnection(this.ws)) {
                getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (response) => {
                    this.ws = new WebSocket(`${CHAPCHE_WEBSOCKET_URL}/ws/v1?token=${response.wsToken}`);
                    this.ws.onmessage = e => {
                        try {
                            this.notifyNotificationObservers(e.data);
                        } catch (err) {
                            console.log(err);
                        }
                    };
                    this.ws.onclose = () => this.createWSConnection(onOpenCallback, count++);
                    this.ws.onerror = () => this.createWSConnection(onOpenCallback, count++);
                    this.ws.onopen = function () {
                        onOpenCallback && onOpenCallback();
                        clearInterval(this.timeout);
                        count = 0;
                    };
                });
            }
        }, getConnectionIntervalTime(count));
    }

    sendMessage(eventType, body) {
        if(checkConnection(this.ws)) {
            this.ws.send(JSON.stringify({event: eventType, body}));
        } else {
            this.createWSConnection(() => {
                this.ws.send(JSON.stringify({event: eventType, body}));
            }, 0);
        }
    }

    registerNotificationObserver(func) {
        this.coreObservers.push(func);
    }

    unRegisterNotificationObserver(func) {
        try {
            this.coreObservers = this.coreObservers.filter(fn => fn !== func);
        } catch(err) {
            console.log(err);
        }
    }

    cleanObservers() {
        this.coreObservers = [];
    }

    notifyNotificationObservers(message) {
        this.coreObservers.map(observerFunction => observerFunction(message));
    }
}

let WSConnectionService = new WebSocketConnectionService();

export const handleCreateNewWebSocketAsyncService = () => {
    WSConnectionService = new WebSocketConnectionService();
    WSConnectionService.createWSConnection(() => {}, 0);
};

export const registerObserver = (func) => {
    WSConnectionService.registerNotificationObserver(func);
};

export const unRegisterObserver = (func) => {
    WSConnectionService.unRegisterNotificationObserver(func);
};

export const sendWSMessage = (eventType, body) => {
    WSConnectionService.sendMessage(eventType, body);
};
