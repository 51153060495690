import {
    CHECK_ITEM_CODE_REG_EXP, 
    I18N_CODE_VALIDATION_ERROR, 
    I18N_CODE_EXISTS_ERROR, 
    I18N_CODE_DELETED_FORM_ERROR,
} from "../constant";
import { i18n } from "../i18n";
import { FORMS_URL, getSyncFetchRequest, postFetchRequest } from "../store/requests";
import {errorCallback, makeUrl} from "../util";

class formCreateService {
    observersList = [];
    itemCode = '';
    error = '';
    registerObserver (observer) {
        this.observersList.push(observer);
    }

    unRegisterObserver (observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    }

    notifyAboutCodeChanging () {
        this.observersList.forEach(observer => observer(this.itemCode));
    }

    changeItemCode (code) {
        this.error = '';
        this.itemCode = code;
        this.checkCodeValidation();
        this.notifyAboutCodeChanging();
    }

    checkCodeValidation () {
        if(this.itemCode === undefined || this.itemCode === null || !this.itemCode.match(CHECK_ITEM_CODE_REG_EXP)) {
            this.error = i18n.get(I18N_CODE_VALIDATION_ERROR);
        }
        return this.error;
    }
    
    async saveNewItem (callback) {
        const checkItemsCode = await this.findItemCodeInItems();
        if(!this.checkCodeValidation() && !checkItemsCode) {
            postFetchRequest(makeUrl([FORMS_URL]), {productForm: this.itemCode}, callback, errorCallback);
        } else {
            this.error = checkItemsCode;
            this.notifyAboutCodeChanging();
        }
    }

    async findItemCodeInItems() {
        const listOfForms = await getSyncFetchRequest(makeUrl([FORMS_URL, '?withDeleted=true&withStatus=true']));
        let result = false;
        listOfForms.forEach(it => {
            if(it.productForm === this.itemCode) {
                result = it.isDeleted ? 
                    i18n.get(I18N_CODE_DELETED_FORM_ERROR) 
                    : 
                    i18n.get(I18N_CODE_EXISTS_ERROR);
            }
        });
        return result;
    }
}

export let formCreateServiceItem;

export const initializeFormCreateService = () => {
    formCreateServiceItem = new formCreateService();
};
