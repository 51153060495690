import React, { useState, useEffect } from 'react';
import SelectHandler from '../sharedComponents/SelectHandler';
import { i18n } from '../../i18n';
import {
    PREFERENCE_TYPE_CEREAL_MIX,
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_RECIPE,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
} from '../../constant';
import IngredientHandler from '../recipes/IngredientHandler';
import { preferenceUpdateCerealMixService } from '../../services/preferenceUpdateCerealMixService';
import { productsService } from '../../services/productsService';
import PreferenceStructureValue from './PreferenceStructureValue';
import { propertiesService } from '../../services/propertiesService';

const findPreferenceTitle = (itemSaveService) => {
    if(itemSaveService.getPreference().preferenceItemCodes && itemSaveService.getPreference().preferenceItemCodes.length) {
        if(itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE) {
            return i18n.get(`recipe.item.type.${itemSaveService.getPreference().preferenceItemCodes[0].itemCode}`);
        } else if(itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
            return i18n.get(`app.page.recipe.recipeCharacteristic.${itemSaveService.getPreference().preferenceItemCodes[0].itemCode}`);
        } else if(itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY) {
            return propertiesService.getLocalization(i18n.APP_LOCALE, itemSaveService.getProperty().itemCode, true);
        } else {
            return productsService.getLocalization(i18n.APP_LOCALE, itemSaveService.getProperty().itemCode, true);
        }
    } else {
        return '';
    }
};

const checkNeedToAddValueField = (itemSaveService) => {
    if (itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY ||
        itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
        return itemSaveService.getPreference().preferenceItemCodes &&
        itemSaveService.getPreference().preferenceItemCodes[0];
    }
    return false;
};

const PreferenceStructureItemCode = ({ itemSaveService }) => {
    const [preferenceTypeIsChanged, setPreferenceTypeIsChanged] = useState(null);

    const updatePreferenceValue = (value) => {
        itemSaveService.updatePreferenceProperty('preferenceItemCodes', [{'itemCode': value}]);
    };

    const changePreferenceType = () => {
        setPreferenceTypeIsChanged(prev => !prev);
    };

    useEffect(() => {
        itemSaveService.registerItemUpdateObserver(changePreferenceType);
        return () => itemSaveService.unRegisterItemUpdateObserver(changePreferenceType);
    }, []);

    return (
        <label className="block__text preference__form-label">
            <span>{i18n.get('app.page.preference.addTitles.preferenceItemCode')}</span>
            {itemSaveService.getPreference().preferenceType === PREFERENCE_TYPE_CEREAL_MIX && preferenceTypeIsChanged !== null ?
                <IngredientHandler selectWithTokensManager={preferenceUpdateCerealMixService} />
                :
                <SelectHandler 
                    selectedValue={findPreferenceTitle(itemSaveService)} 
                    optionsList={itemSaveService.getPossibleCodeValues()}
                    sendSelectedValue={updatePreferenceValue}
                /> }
            {checkNeedToAddValueField(itemSaveService) && preferenceTypeIsChanged !== null ?
                <PreferenceStructureValue />
                :
                null}
        </label> 
    );
};

export default PreferenceStructureItemCode;
