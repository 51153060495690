import React, { useState, useRef } from 'react';
import { GET_SOURCES, postFetchRequest } from '../../store/requests';
import { i18n } from '../../i18n';
import { propertyValuesSourcesStorage } from '../../services/propertyValuesSourcesStorage';
import {errorCallback, makeUrl} from "../../util";

const CreateSourceBlock = ({ closeCallback }) => {
    const [newSourceName, setNewSourceName] = useState('');
    const ref = useRef(null);

    const handleAddNewSource = () => {
        postFetchRequest(makeUrl([GET_SOURCES]), {'sourceName': newSourceName}, 
            () => propertyValuesSourcesStorage.loadItems(closeCallback), errorCallback);
    };

    const handleChangeTextarea = (e) => {
        setNewSourceName(e.target.value);
        if (ref.current) {
            ref.current.style.height = "auto";
            ref.current.style.height = `${e.target.scrollHeight + 2}px`;
            ref.current.style.padding = "7px";
        }
    };

    return (
        <>
            <h3 className="block__text product__source-subtitle">{i18n.get("app.page.product.selectSource")}</h3>
            <textarea className="block__text product__source-name" value={newSourceName} onChange={handleChangeTextarea}
                ref={ref} placeholder={i18n.get("app.page.product.writeSourceData")} />
            <div className="block__button_small product__source-buttons">
                <span className="product__source-button product__source-button_thin" onClick={() => closeCallback(false)}>{i18n.get("app.page.product.close")}</span>
                <span className="product__source-button" onClick={handleAddNewSource}>{i18n.get("app.page.product.add")}</span>
            </div>
        </>
    );
};

export default CreateSourceBlock;
