import React, { useState, useEffect } from 'react';
import { GET_USER_REQUEST, getFetchRequest, postFetchRequest } from '../store/requests';
import {
    ADMIN_CONSOLE_BUSINESS_OBJECT,
    AUTH_PERMISSION,
    CABINET_BUSINESS_OBJECT,
    ERROR_WRONG_EMAIL_TYPE,
    ERROR_WRONG_ROLE_TYPE,
    GUEST_ROLE,
    LOCALE_RU,
    PERMISSION_LEVEL_BUSINESS_OBJECT,
    PERMISSION_LEVEL_COMPONENT,
    TABLET_WIDTH,
    currentPermissions,
} from '../constant';
import { ErrorHandler } from './ErrorHandler';
import { ChevronIcon } from '../appearence/icons/chevron_icon';
import SelectWithoutTokens from './sharedComponents/SelectWithoutTokens';
import { i18n } from '../i18n';
import useWindowDimensions from '../hooks/useWindowDimension';
import ProfilesTab from './ProfilesTab';
import CustomCheckbox from './CustomCheckbox';
import PhoneInput from './sharedComponents/PhoneInput';
import {errorCallback, makeUrl} from "../util";
import {rolesStorage} from "../services/rolesStorage";


const UsersTable = ({usersList, isTab, handleChange = null, findCheckedValues = null}) => {
    try {
        return (
            <table className="block__table users__table">
                <thead>
                    <tr className="users__table-header">
                        {isTab ? <th></th> : null}
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.name')}/id
                        </th>
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.phone')}/{i18n.get('app.page.user.post')}
                        </th>
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.role')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {usersList && usersList.length ? 
                        usersList.map((it, index) => 
                            isTab || index < 4 ?
                                <tr className="users__table-row" key={it.id}>
                                    {isTab ? 
                                        <td className="users__tab-checkbox">
                                            <CustomCheckbox handleChange={handleChange} checked={findCheckedValues(it.id)} values={{value: it}}/> 
                                        </td>
                                        : 
                                        null}
                                    <td className="users__table-item">
                                        {it.name}
                                        {it.name && !isTab ? null : <p className={isTab ? "block__bullet users__tab-id users__tab-content" : "users__tab-data"}>{it.id}</p>}
                                    </td>
                                    <td className="users__table-item">
                                        {it.phone}
                                        {it.phone && !isTab ? null : <p className={isTab ? "users__tab-content" : "users__tab-data"}>{it.email}</p>}
                                    </td>
                                    <td className="users__table-item">{i18n.get(`appRoles.scheme.userRoles.${it.appRole.code}`)}</td>
                                </tr>
                                :
                                '',
                        )
                        :
                        null}
                </tbody>
            </table>
        );
    } catch(err) {
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

const getValueOfPermissions = (arrayOfPermissions, entryPoint, key) => {
    let result = false;
    if (arrayOfPermissions.length) {
        entryPoint === CABINET_BUSINESS_OBJECT || entryPoint === ADMIN_CONSOLE_BUSINESS_OBJECT ?
            arrayOfPermissions.forEach(it => it.targetName === entryPoint ? result = it[key] : null) :
            arrayOfPermissions.forEach(it => it.entryPoint === entryPoint ? result = it[key] : null);
    }
    return result;
};

const getRolesPermissions = () => {
    if (!rolesStorage.getRoles() || rolesStorage.getRoles().length === 0) {
        return [];
    }
    return rolesStorage.getRoles()[0].permissions.sort((a, b) => {
        if (a.level === PERMISSION_LEVEL_COMPONENT) { return -1; }
        if (b.level === PERMISSION_LEVEL_COMPONENT) { return 1; }
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
    }).map(it => {
        return {...it, isOpen: false};
    });
};

const getRoles = () => {
    return rolesStorage.getRoles()
        .sort((a, b) => {
            if (a.code === GUEST_ROLE) return -1;
            return 0;
        })
        .sort((a, b) => {
            if (a.code < b.code) { return -1; }
            if (a.code > b.code) { return 1; }
            return 0;
        });
};

const makeSmallStringInTabletVersion = (string) => {
    let result = '';
    if(string === i18n.get('app.page.appRole.businessObjectAndRoles.user_preference')) {
        result = i18n.get('app.page.appRole.businessObjectAndRoles.user_preference');
    } else if(string === i18n.get('app.page.appRole.businessObjectAndRoles.cabinet')) {
        result = i18n.get('app.page.appRole.businessObjectAndRoles.cabinet').split(' ').reverse()[0];
    } else if(string === i18n.get('app.page.appRole.businessObjectAndRoles.admin_console')) {
        const array = i18n.get('app.page.appRole.businessObjectAndRoles.admin_console').split(' ');
        result = array.slice(-2).join(' ');
    } else {
        result = string.split(' ')[0];
    }
    return result;
};

const handleChangePermission = (e, role, permissionKey, row) => {
    const preparedData = {...role};
    if (row.level === PERMISSION_LEVEL_BUSINESS_OBJECT) {
        preparedData.permissions.forEach((it) => {
            if (row.entryPoint === it.entryPoint) {
                it[permissionKey] = e.target.checked;
            }
        });
    } else {
        preparedData.permissions.forEach((it) => {
            if (row.targetName === it.targetName) {
                it.hasAccess = e.target.checked;
            }
        });
    }
    rolesStorage.updateRolePermission(preparedData);
};

const PermissionsListElem = ({ rolePermission }) => {
    try {
        return (
            currentPermissions.map((permission, index) => {
                return (
                    <tr key={permission.key} className={index === currentPermissions.length - 1 ? "users__table-roles-row" : ""}>
                        <td className="users__table-item users__table-permission">
                            {i18n.get(`appRoles.scheme.permissions.${permission.key}`)}
                        </td>
                        {
                            getRoles().map((it) => {
                                return (
                                    <td key={it.id} className="users__table-item">
                                        <BusinessObjectPermissionItem role={it} rolePermission={rolePermission} permissionKey={permission.key}/>
                                    </td>
                                );
                            })
                        }
                    </tr>
                );
            })
        );
    } catch(err) {
        console.log(err);
        return null;
    }
};

const BusinessObjectPermissionItem = ({role, rolePermission, permissionKey}) => {
    const [isActive, setIsActive] = useState(false);
    const getIsActive = () => {
        return role.permissions.length ? getValueOfPermissions(role.permissions, rolePermission.entryPoint, permissionKey) : false;
    };
    const handleIsActive = (e) => {
        setIsActive(!isActive);
        handleChangePermission(e, role, permissionKey, rolePermission);
    };
    useEffect(() => {
        setIsActive(getIsActive());
    }, [role, rolePermission, permissionKey]);
    return (
        <CustomCheckbox handleChange={handleIsActive} checked={isActive} />
    );
};

const ComponentPermissionItem = ({role, permission}) => {
    const [isActive, setIsActive] = useState(false);
    const getIsActive = () => {
        return role.permissions.length ? getValueOfPermissions(role.permissions, permission.targetName, 'hasAccess') : false;
    };
    const handleIsActive = (e) => {
        setIsActive(!isActive);
        handleChangePermission(e, role, permission.targetName, permission);
    };
    useEffect(() => {
        setIsActive(getIsActive());
    }, [role, permission]);
    return (
        <CustomCheckbox handleChange={handleIsActive} checked={isActive} />
    );
};

const RolesTable = ({initialRolesPermissions}) => {
    const [rolesPermissions, setRolesPermissions] = useState([]);
    useEffect(() => {
        setRolesPermissions(initialRolesPermissions);
    }, [initialRolesPermissions]);
    const handleSetRoleIsOpen = (index) => {
        rolesPermissions[index].isOpen = !rolesPermissions[index].isOpen;
        setRolesPermissions([...rolesPermissions]);
    };

    const { width } = useWindowDimensions();
    try {
        return (
            <div className="users__table-wrapper">
                <table className="block__table users__table users__table-roles">
                    <thead>
                        <tr className="users__table-header users__table-roles-header">
                            <th className="users__table-item users__table-item_sticky">
                                {width <= TABLET_WIDTH ?
                                    i18n.get('app.page.appRole.businessObjectAndRoles.object')
                                    :
                                    <>
                                        <span className="users__table-item_capitalize">{i18n.get('app.page.appRole.businessObjectAndRoles.businessObject')}</span>
                                        /
                                        <span className="users__table-item_capitalize">{i18n.get('app.page.appRole.businessObjectAndRoles.roles')}</span>
                                    </>}
                            </th>
                            {getRoles().map((it, index) => (
                                <th className="users__table-item users__table-item_sticky" key={index + 'header'}>
                                    {width <= TABLET_WIDTH ?
                                        makeSmallStringInTabletVersion(i18n.get(`appRoles.scheme.userRoles.${it.code}`))
                                        :
                                        i18n.get(`appRoles.scheme.userRoles.${it.code}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rolesPermissions.map((permission, index) => (
                            <React.Fragment key={index + 'rolesList'}>
                                {permission.targetName !== AUTH_PERMISSION && <tr className={permission.isOpen ? "" : "users__table-roles-row"} key={permission.entryPoint} >
                                    <td className="users__table-item users__table-title" onClick={() => permission.level !== PERMISSION_LEVEL_COMPONENT ? handleSetRoleIsOpen(index) : null}>
                                        {width <= TABLET_WIDTH ?
                                            makeSmallStringInTabletVersion(i18n.get(`app.page.appRole.businessObjectAndRoles.${permission.targetName}`))
                                            :
                                            i18n.get(`app.page.appRole.businessObjectAndRoles.${permission.targetName}`)}
                                    </td>
                                    {getRoles().map((role, indexItem) => {
                                        return (
                                            <td key={role.id} className="users__table-item">
                                                {permission.level === PERMISSION_LEVEL_COMPONENT ?
                                                    <ComponentPermissionItem role={role} permission={permission}/>
                                                    : indexItem === 0 ?
                                                        <span onClick={() => permission.level !== PERMISSION_LEVEL_COMPONENT ? handleSetRoleIsOpen(index) : null}>
                                                            <ChevronIcon nameOfClass={permission.isOpen ? 'users__table-icon_active' : 'users__table-icon'} />
                                                        </span> 
                                                        : 
                                                        null}
                                            </td>
                                        );
                                    })}

                                </tr>}
                                {permission.isOpen ? <PermissionsListElem rolePermission={permission} /> : ''}
                            </React.Fragment>))}
                    </tbody>
                </table>
            </div>
        );
    } catch(err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

const getPossibleRoles = () => {
    return rolesStorage.getRoles().map(it => {
        return {id: it.id, code: it.code, name: it.name};
    });
};

const UsersPage = ({currentUserData}) => {
    const [inviteNewUserForm, setInviteNewUserForm] = useState({email: '', appRoleId:'', locale: LOCALE_RU});
    const [phoneInput, setPhoneInput] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [error, setError] = useState({type:'', message:''});
    const [usersListModalIsOpen, setUsersListModalIsOpen] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState({});
    const [needPageRerender, setNeedPageRerender] = useState(false);

    const needToRerenderRoles = () => {
        setNeedPageRerender(!needPageRerender);
    };

    useEffect(() => {
        rolesStorage.registerRolesChangingObserver(needToRerenderRoles);
        return () => rolesStorage.unRegisterRolesChangingObserver(needToRerenderRoles);
    }, []);

    useEffect(() => {
        setCurrentUserRole(currentUserData.appRole);
    }, []);

    useEffect(() => {
        if(currentUserRole.code) {
            getFetchRequest(makeUrl([GET_USER_REQUEST]), (response) => setUsersList(response), errorCallback);
            rolesStorage.loadItems();
        }
    },[currentUserRole]);

    const handleChangeInviteNewUserFormEmail = (e) => {
        inviteNewUserForm[e.target.name] = e.target.value;
        setInviteNewUserForm({...inviteNewUserForm});
    };

    const handleChangeInviteNewUserFormRole = (role) => {
        inviteNewUserForm['appRoleId'] = role.id;
        setInviteNewUserForm({...inviteNewUserForm});
    };

    const successCallbackInviteUser = (response) => {
        if(response.ok) {
            setInviteNewUserForm({email: '', appRoleId:'', locale: LOCALE_RU});
            setPhoneInput('');
            getFetchRequest(makeUrl([GET_USER_REQUEST]), (response) => setUsersList(response), errorCallback);
        }
    };

    const handleInviteUser = () => {
        if((inviteNewUserForm.email || phoneInput) && inviteNewUserForm.appRoleId ) {
            const preparedData = {
                appRoleId: inviteNewUserForm.appRoleId,
                locale: inviteNewUserForm.locale,
            };
            phoneInput ? preparedData.phone = `+7${phoneInput}` : preparedData.email = inviteNewUserForm.email;
            postFetchRequest(makeUrl([GET_USER_REQUEST]), preparedData, successCallbackInviteUser, errorCallback, true);
        } else {
            setError({type: inviteNewUserForm.email ? ERROR_WRONG_ROLE_TYPE : ERROR_WRONG_EMAIL_TYPE, message:''});
        }
    };

    return (
        <section className="workspace">
            {usersListModalIsOpen ? 
                <ProfilesTab setUsersListModalIsOpen={setUsersListModalIsOpen} usersList={usersList} setUsersList={setUsersList} UsersTable={UsersTable}/>
                :
                <>
                    <h1 className="users__title block__title-h2">{i18n.get('app.page.user.title')}</h1>
                    <div className="users__wrapper">
                        <article className="users__article">
                            <h2 className="users__subtitle users__subtitle-send block__title-h3">{i18n.get('app.page.user.sendInvite')}</h2>
                            <form className="users__form">
                                <label className="block__text users__label" >
                                    <span className="product__form-label_text">{i18n.get('app.page.user.email')}</span>
                                    <input className={`users__input ${error.type === ERROR_WRONG_EMAIL_TYPE ? 'login__input_error' : ''}`} 
                                        value={inviteNewUserForm.email}
                                        name="email"
                                        onChange={handleChangeInviteNewUserFormEmail}/>
                                </label>
                                <PhoneInput phone={phoneInput} setPhoneInput={setPhoneInput} error={error} />
                                <p className="block__text users__content">{i18n.get('app.page.user.selectRole')}</p>
                                <SelectWithoutTokens  listOfOptions={getPossibleRoles()} handleChangeValue={handleChangeInviteNewUserFormRole} titleValue={''} />
                                <button type="button" onClick={handleInviteUser}
                                    className={`block__button button users__button 
                                        ${(inviteNewUserForm.email || phoneInput) && inviteNewUserForm.appRoleId ? '' : 'button_disabled'}`}>
                                    <span className="button__content">{i18n.get('app.page.user.send')}</span>
                                </button>
                            </form>
                        </article>
                        <article className="users__article users__article-list">
                            <h2 className="users__subtitle block__title-h3">{i18n.get('app.page.user.residentsData')}</h2>
                            <UsersTable usersList={usersList} isTab={false} />
                            <p className="block__table users__more navigation-link" onClick={() => setUsersListModalIsOpen(true)}>
                                {i18n.get('app.page.user.more')}
                            </p>
                        </article>
                    </div>
                    
                    <article className="users__article users__article_full-width">
                        <h2 className="users__subtitle block__title-h3">{i18n.get('app.page.user.roleAssignment')}</h2>
                        <RolesTable initialRolesPermissions={getRolesPermissions()}/>
                    </article>
                </>}
        </section>
    );
};

export default UsersPage;
