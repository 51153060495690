import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { popupOpenService } from '../../services/popupOpenService';
import { initializeRecipeCreateService, recipeCreateServiceItem } from '../../services/recipeCreateService';
import SelectWithoutTokens from '../sharedComponents/SelectWithoutTokens';
import {POPUP_KEY_CREATE_RECIPE, RECIPE_TYPES} from '../../constant';
import { i18n } from '../../i18n';
import { keyDownService } from '../../services/keyDownService';
import { MAIN_RECIPE_ELEMENTS } from '../helper';

const createFullList = () => {
    const result = [];
    RECIPE_TYPES.forEach(it => {
        result.push({id: it, code: it, name: i18n.get(`recipe.item.type.${it}`)});
    });
    return result;
};

const CreateNewRecipePopup = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [recipesEnumList, setRecipesEnumList] = useState({});
    const [newRecipeCode, setNewRecipeCode] = useState('');
    const navigate = useNavigate();

    const changePopupVisibility = () => {
        setIsVisible(true);
        setRecipesEnumList({fullList: createFullList(), activeList:[], firstRender: false});
    };

    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_CREATE_RECIPE, changePopupVisibility);
        initializeRecipeCreateService();
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_CREATE_RECIPE, changePopupVisibility);
    }, []);

    const redirectToEditPage = (response) => {
        navigate(`/recipe/item/${response.id}/?ingredientTab=${MAIN_RECIPE_ELEMENTS}`);
    };

    const createNewItem = () => {
        recipeCreateServiceItem.saveNewItem(redirectToEditPage);
    };

    const changeRecipeCode = (recipe) => {
        setNewRecipeCode(recipe.code);
        recipeCreateServiceItem.changeItemCode(recipe.code);
    };

    return (
        isVisible ?
            <>
                <div className="modal__wrapper modal__wrapper_active" onClick={() => setIsVisible(false)}></div>  
                <article className="modal modal_small" onKeyDown={(e) => keyDownService.saveNewItem(e, createNewItem)} tabIndex="0">
                    <h2 className="modal__title block__title-h3">{i18n.get(`app.page.recipe.modal.createRecipe.title`)}</h2>
                    <p className="block__content modal__text product__modal-text">{i18n.get(`app.page.recipe.modal.createRecipe.content`)}</p>
                    <label className={`block__text recipe__form-label recipe__form_lowercase recipe__form-modal ${recipesEnumList.activeList.length ? 'recipe__form-modal_active' : ''}`}>
                        {i18n.get('app.page.recipe.recipeType')}
                        <SelectWithoutTokens  listOfOptions={createFullList()} handleChangeValue={changeRecipeCode} titleValue={''} />
                    </label>
                    <div className="modal__button-group">
                        <button className="block__button button button_outline" onClick={() => setIsVisible(null)}>
                            <span className="button__content">{i18n.get(`app.page.recipe.modal.createRecipe.cancel`)}</span>
                        </button>
                        <button className={`block__button button ${newRecipeCode ? '' : 'button_disabled'}`} onClick={createNewItem}>
                            <span className="button__content">{i18n.get(`app.page.recipe.modal.createRecipe.create`)}</span>
                        </button>
                    </div>
                </article>
            </>
            :
            null
    );
};

export default CreateNewRecipePopup;
