import React from 'react';
import ListItemTitle from '../sharedComponents/ListItemTitle';
import { recipeDeleteService } from '../../services/recipeDeleteService';
import { POPUP_KEY_DELETE_RECIPE } from '../../constant';
import DeleteItemIcon from '../sharedComponents/DeleteItemIcon';
import EditItemIcon from '../sharedComponents/EditItemIcon';
import { MAIN_RECIPE_ELEMENTS } from '../helper';
import { recipeNameService } from "../../services/recipeNameService";

const RecipeListItem = ({recipeId, recipe}) => {
    return (
        <>
            <ListItemTitle itemCode={recipe} itemService={recipeNameService}/>
            <p className="main__subtitle recipe__list-content">{recipe.recipeType}</p>
            <div className="recipe__list-icons">
                <EditItemIcon pageUrl={`/recipe/item/${recipeId}/?ingredientTab=${MAIN_RECIPE_ELEMENTS}`} />
                <DeleteItemIcon 
                    itemId={recipeId} 
                    itemDeleteService={recipeDeleteService} 
                    popupKey={POPUP_KEY_DELETE_RECIPE} 
                />
            </div>
        </>
    );
};

export default RecipeListItem;
