import React, { useEffect, useState } from 'react';
import { POPUP_KEY_ASSIGN_USER } from '../../constant';
import { popupOpenService } from '../sharedComponents/popupOpenService';
import { preferencesStorage } from '../../services/preferencesStorage';
import { PREFERENCE_URL, postFetchRequest } from '../../store/requests';
import AssignPreferenceUsersSearchInput from './AssignPreferenceUsersSearchInput';
import AssignPreferenceUsersList from './AssignPreferenceUsersList';
import { initializeUsersService, userPreferencesService } from '../../services/userPreferencesService';
import Modal from '../Modal';
import { MODAL_SMALL_SIZE } from '../helper';
import { i18n } from '../../i18n';
import {errorCallback, makeUrl} from "../../util";

const AssignUserModalContent = ({ handleClose, assignPreferences }) => {
    return (
        <>
            <h2 className="preference__users-title block__title-h3">{i18n.get('app.page.preference.preferenceAssignment')}</h2>
            <AssignPreferenceUsersSearchInput />
            <AssignPreferenceUsersList />
            <div className="modal__button-group">
                <button className="block__button button preference__users-button" onClick={handleClose}>
                    <span className="button__content">{i18n.get('app.page.preference.modal.cancel')}</span>
                </button>
                <button className="block__button button button_outline preference__users-button" onClick={assignPreferences}>
                    <span className="button__content">{i18n.get('app.page.preference.modal.assign')}</span>
                </button>
            </div>
        </>
    );
};

const prepareUserBody = () => {
    const preparedList = [];
    userPreferencesService.getSelectedUsersList().forEach(it => preparedList.push(it.id));
    return preparedList;
};

const AssignPreferenceToUserPopup = ({ notifyPreferencesList }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenModal = () => {
        setIsOpen(true);
    };    
    
    useEffect(() => {
        popupOpenService.registerPopup(POPUP_KEY_ASSIGN_USER, handleOpenModal);
        initializeUsersService();
        return () => popupOpenService.unRegisterPopup(POPUP_KEY_ASSIGN_USER, handleOpenModal);
    },[]);

    const closePopup = () => {
        setIsOpen(false);
    };

    const assignPreferences = () => {        
        if(preferencesStorage.getSelectedPreferencesList().length && userPreferencesService.getSelectedUsersList().length) {
            for(let preference of preferencesStorage.getSelectedPreferencesList()) {
                postFetchRequest(makeUrl([PREFERENCE_URL, preference.id, '/user/']), prepareUserBody(), closePopup, errorCallback, true);
            }
        }
        userPreferencesService.changeSelectedUsersList(null, true);
        preferencesStorage.changeSelectedPreferencesList(null, true);
        notifyPreferencesList();
    };

    return (
        isOpen ?
            <Modal content={<AssignUserModalContent handleClose={closePopup} assignPreferences={assignPreferences} />}  
                handleCloseModal={closePopup} size={MODAL_SMALL_SIZE}/>
            :
            null
    );
};

export default AssignPreferenceToUserPopup;
