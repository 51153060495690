import { i18n } from "../../i18n";
import { makeUrl } from "../../util";
import { RESET_PASSWORD_REQUEST, postFetchRequest, GET_USER_REQUEST } from "../../store/requests";

export const OLD_PASSWORD_KEY = 'old-password';
export const NEW_PASSWORD_KEY = 'new-password';
export const REPEAT_PASSWORD_KEY = 'repeat-password';
export const ERROR_TYPE = 'error';
export const TEXT_TYPE = 'text';
const CHECK_SYMBOL_REGEXP = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+/;
const CHECK_LETTER_REGEXP = /[a-zA-Z]+/;
const CHECK_DIGITS_REGEXP = /[0-9]+/;

const checkPasswordString = (password) => {
    return CHECK_DIGITS_REGEXP.test(password) && CHECK_LETTER_REGEXP.test(password) && CHECK_SYMBOL_REGEXP.test(password);
};

export const loginManager = {
    password: '',
    oldPassword: '',
    repeatedPassword: '',
    error: {},
    observersList: [],
    updatePasswordValue(key, value) {
        try {
            if(key === OLD_PASSWORD_KEY) {
                this.oldPassword = value;
            }
            if(key === NEW_PASSWORD_KEY) {
                this.password = value;
            }
            if(key === REPEAT_PASSWORD_KEY) {
                this.repeatedPassword = value;
            }
            if(!checkPasswordString(value) || (this.repeatedPassword && (this.password !== this.repeatedPassword))) {
                this.error = !checkPasswordString(value) ?
                    {type: ERROR_TYPE, key, message: i18n.get('public.app.page.login.passwordValidation')}
                    :
                    {type: ERROR_TYPE, key: REPEAT_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordsIsNotEqual')};
            } else {
                this.error = {type: TEXT_TYPE, key, message: i18n.get('public.app.page.login.passwordValidation')};
            }
            this.notifyLoginUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },

    sendUpdatePasswordRequest(code, callback) {
        if(this.password !== this.repeatedPassword) {
            this.error = {type: ERROR_TYPE, key: REPEAT_PASSWORD_KEY + NEW_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordsIsNotEqual')};
            this.notifyLoginUpdateObservers();
            return;
        }
        if(!checkPasswordString(this.password)) {
            this.error = {type: ERROR_TYPE, key: NEW_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordValidation')};
            this.notifyLoginUpdateObservers();
            return;
        }
        const data = {
            code,
            password: this.password,
        };
        postFetchRequest(makeUrl([RESET_PASSWORD_REQUEST]), data, 
            (response) => {
                if(response.ok) {
                    callback();
                    this.password = '';
                    this.oldPassword = '';
                    this.repeatedPassword = '';
                } else {
                    this.error = {type: ERROR_TYPE, key: REPEAT_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordValidation')};
                    this.notifyLoginUpdateObservers();
                }
            },
            () => {
                this.error = {type: ERROR_TYPE, key: REPEAT_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordValidation')};
                this.notifyLoginUpdateObservers();
            }, true);
    },

    loadError() {
        this.error = {type: TEXT_TYPE, key: NEW_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordValidation')};
        this.notifyLoginUpdateObservers();
    },

    getError() {
        return this.error;
    },

    registerLoginUpdateObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterLoginUpdateObserver(observer) {
        try {
            this.observersList = this.observersList.filter(func => func !== observer);
        } catch(err) {
            console.log(err);
        }
    },
    notifyLoginUpdateObservers() {
        this.observersList.forEach(observer => observer());
    },

    sendChangePasswordRequest(userId, callback) {
        if(this.password !== this.repeatedPassword) {
            this.error = {type: ERROR_TYPE, key: REPEAT_PASSWORD_KEY + NEW_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordsIsNotEqual')};
            this.notifyLoginUpdateObservers();
            return;
        }
        if(!checkPasswordString(this.password)) {
            this.error = {type: ERROR_TYPE, key: NEW_PASSWORD_KEY, message: i18n.get('public.app.page.login.passwordValidation')};
            this.notifyLoginUpdateObservers();
            return;
        }
        const data = {
            currentPassword: this.oldPassword,
            newPassword: this.newPassword,
        };
        postFetchRequest(makeUrl([GET_USER_REQUEST, userId, '/password/']), data, 
            () =>{
                callback();
                this.password = '';
                this.oldPassword = '';
                this.repeatedPassword = '';
            }, 
            () => {
                this.error = {type: ERROR_TYPE, key: OLD_PASSWORD_KEY, message: i18n.get('app.page.profile.wrongPassword')};
                this.notifyLoginUpdateObservers();
                return;
            }, true);
    },

    canSendRequest() {
        return this.error.type !== ERROR_TYPE && this.password && this.repeatedPassword && this.password === this.repeatedPassword;
    },
};
