import { GET_SOURCES, getFetchRequest } from "../store/requests";
import {errorCallback, makeUrl} from "../util";

export const propertyValuesSourcesStorage = {
    sourcesList: [],
    searchValue: '',
    observersList: [],
    loadItems(callback) {
        try {
            getFetchRequest(makeUrl([GET_SOURCES]), (response) => {
                this.sourcesList = response;
                callback && callback(response);
                this.notifyFiltersChangingObservers();
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },

    getItems() {
        let filteredList = [...this.sourcesList];
        if(this.searchValue) {
            filteredList = filteredList.filter(it => it.sourceName.toLowerCase().includes(this.searchValue));
        }
        return filteredList;
    },

    setSearchString(searchStringValue) {
        this.searchValue = searchStringValue;
        this.notifyFiltersChangingObservers();
    },

    getSearchString() {
        return this.searchValue;
    },

    registerFiltersChangingObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterFiltersChangingObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },

    notifyFiltersChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getById(itemId) {
        return this.sourcesList.find(it => it.sourceId === itemId);
    },
};
