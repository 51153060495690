import React from 'react';
import { useSearchParams } from 'react-router-dom';
import AddNewIngredientButton from './AddNewIngredientButton';
import { recipeSaveService } from '../../services/recipeSaveService';
import IngredientsListItem from './IngredientsListItem';

const RecipeIngredientGroupsTabContent = () => {
    const [searchParams] = useSearchParams();
    const ingredientTab = searchParams.get('ingredientTab');
    return (
        <>
            <AddNewIngredientButton />
            <ul className="recipe__add-list">
                {recipeSaveService.getRecipe().ingredient && recipeSaveService.getRecipe().ingredient.length ?
                    recipeSaveService.getRecipe().ingredient.map(it => (
                        it.ingredientGroup === ingredientTab ?
                            <React.Fragment key={it.id}>
                                <IngredientsListItem ingredient={it} />  
                            </React.Fragment>
                            : 
                            null
                    ))
                    :
                    null
                }                      
            </ul>
        </>
        
    );
};

export default RecipeIngredientGroupsTabContent;
